import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Slider from '@material-ui/core/Slider';
import { ThemeProvider } from '@material-ui/styles';
import colors from '/domain/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import moment from 'moment';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrow from '@material-ui/icons/PlayArrow';

const theme = createMuiTheme({
  palette: {
    secondary: { main: colors.darkgreen }, // This is just green.A700 as hex.
  },
  overrides: {
    MuiSlider: {
      track: { color: colors.blue, height: 8 },
      rail: { color: colors.gray, height: 8 },
      thumb: { color: colors.darkgreen, height: 17, width: 17 },
    },
  },
});

export default function CountyMapControls({
  formValuesCallback,
  formValues,
  isMobile,
  seriesDay,
  seconds,
}) {
  const [formState, setFormState] = useState({
    ...formValues,
  });
  const [sliderValue, setSliderValue] = useState(null);
  const pauseAction = () => {
    formState.isPaused = true;
    setSliderValue(seconds);
    formValuesCallback({ ...formState, sliderValue: seconds });
  };
  const playAction = () => {
    const newFormState = {
      ...formState,
      sliderValue: sliderValue,
      isPaused: false,
      resume: formState.isPaused,
    };
    formValuesCallback(newFormState);
    setFormState({ ...newFormState });
  };

  const dateFormat = 'M/DD';
  const sliderRange = formValues.endDate.diff(formState.startDate, 'days');
  return (
    <div
      className='rounded-md mb-3 pt-2 pb-1'
      style={
        isMobile
          ? {
              backgroundColor: 'white',
              zIndex: 70000,
              width: '95%',
              opacity: 0.9,
            }
          : {
              backgroundColor: 'white',
              zIndex: 70000,
              width: 796,
              opacity: 0.9,
            }
      }
    >
      <>
        <div
          className={
            isMobile ? 'text-center pb-2' : 'px-3 pb-1 w-full text-center'
          }
        >
          <ThemeProvider theme={theme}>
            <div style={{ height: 15 }}>
              <FormLabel style={{ color: `${colors.gray}`, fontWeight: 700 }}>
                {seriesDay.format('MMMM Do YYYY')}
              </FormLabel>
            </div>
            <div
              className={
                isMobile
                  ? 'w-full flex items-center justify-center mb-3'
                  : 'w-full flex items-center justify-center'
              }
            >
              <div
                style={{
                  height: 22,
                  width: 29,
                  marginRight: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    color: `${colors.darkgreen}`,
                  }}
                  className="flex justify-center items-center"
                >
                  {seconds === sliderRange || formState.isPaused ? (
                    <PlayArrow
                      style={{ color: colors.darkgreen }}
                      onClick={playAction}
                      fontSize="large"
                    />
                  ) : (
                    <PauseIcon
                      style={{ color: colors.gray }}
                      onClick={pauseAction}
                    />
                  )}
                </div>
              </div>
              <div
                className={
                  isMobile
                    ? 'flex items-center mr-10'
                    : 'flex items-center mr-8'
                }
              >
                <Slider
                  style={
                    isMobile
                      ? { width: 220, paddingTop: 10, paddingBottom: 15 }
                      : { width: 500, paddingTop: 10, paddingBottom: 15 }
                  }
                  value={formState.isPaused ? sliderValue : seconds}
                  step={1}
                  min={0}
                  max={sliderRange}
                  onPointerDown={() => {
                    if (!formState.isPaused) {
                      pauseAction();
                    }
                  }}
                  onChange={(event, value) => {
                    if (sliderValue !== value) {
                      setSliderValue(value);
                      formValuesCallback({ ...formState, sliderValue: value });
                    }
                  }}
                  getAriaValueText={(value) =>
                    `${moment(formState.startDate)
                      .add(value, 'days')
                      .format(dateFormat)}`
                  }
                  valueLabelFormat={(value) =>
                    `${moment(formState.startDate)
                      .add(value, 'days')
                      .format(dateFormat)}`
                  }
                  valueLabelDisplay="auto"
                  aria-labelledby="continuous-slider"
                />
              </div>
            </div>
            <div>
              <RadioGroup
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                row={!isMobile}
                aria-label="Scale"
                name="severityLevel"
                value={formState.severityLevel}
                onChange={(event) => {
                  seconds = 0
                  formState.sliderValue = 0
                  formState.severityLevel = event.target.value
                  pauseAction()
                  // setFormState({
                  //   ...formState,
                  //   sliderValue: 0,
                  //   severityLevel: event.target.value,
                  // });
                }}
              >
                <FormLabel style={isMobile ? {color: `${colors.gray}`, fontWeight: 600} : {color: `${colors.gray}`, fontWeight: 600, marginRight: 15 }}>
                  Hotspot Type
                </FormLabel>
                <FormControlLabel
                  style={isMobile && { height: 25 }}
                  value="confirmed25"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={<Radio disableRipple color="secondary" />}
                  label="3 day case average > 25"
                />
                <FormControlLabel
                  style={isMobile && { height: 25 }}
                  value="rnought"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={<Radio disableRipple color="secondary" />}
                  label="Rt > 1 (7 day incubation period)"
                />
              </RadioGroup>
            </div>
          </ThemeProvider>
        </div>
      </>
    </div>
  );
}
