import React, { useEffect, useRef, useState, useContext } from "react";
import _ from "lodash";
import {getRegionTimeseriesReusable} from "/domain/api";
import countryBBoxes from "/domain/countryBBoxes";
import ReactMapGL, {
  FlyToInterpolator,
  WebMercatorViewport,
  NavigationControl,
} from "react-map-gl";
import { easeCubic, easeCubicOut } from "d3-ease";
import MapMarkers from "/components/MapMarkers";
import AppStateContext from "/contexts/AppState";
import { useQuery } from "react-query";
const { MAPBOX_TOKEN } = require('/domain/tokens.json')
import moment from "moment";
import CountyMapControls from "./CountyMapControls";

const customSharecareStyle =
  "mapbox://styles/sharecare/cka6jp4s705ec1iqyg8gll0q9?optimize=true" //plain ol white
// "mapbox://styles/sharecare/cka7a9ttb1c5v1iqn2mnbfrcd" //albers white
const USBBox = countryBBoxes.US
function zoomOnArea({
    data,
    isMobile,
    sidebarWidth,
    viewport,
    setViewport,
    transitionDuration,
    mapRef,
  }) {
  if (_.isEmpty(data)) return;

  let newViewport = {
    latitude: _.get(data, "region.latitude", 0),
    longitude: _.get(data, "region.longitude", 0),
  };

  if (_.get(data, "region") && !newViewport.latitude && !newViewport.longitude)
    return;

  newViewport = new WebMercatorViewport(viewport).fitBounds(USBBox, {
    padding: 0,
  });

  let viewportSettings = {
    ...viewport,
    ...newViewport,
    zoom: isMobile ? 2.2 : 3.5,
    transitionDuration: _.isNil(transitionDuration)
      ? "auto"
      : transitionDuration,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: easeCubic,
  }
  setViewport(viewportSettings);
}

function useInitialZoom(appState, viewport, setViewport, isMobile) {
  const mapLoaded = _.get(appState, "mapLoaded");
  useEffect(() => {
    // When the map is loaded, move to a "world" viewport
    if (mapLoaded) {
      const data = _.get(appState, "data");
      const sidebarWidth = _.get(appState, "sidebarWidth", 0);
      appState.actions.initialMapZoomDone();
      zoomOnArea({
        data,
        isMobile,
        sidebarWidth,
        viewport,
        setViewport,
        transitionDuration: _.get(appState, "initialMapZoomDone") ? 1 : 1000,
      });
    }
  }, [mapLoaded]);
}

export default function Map({ isMobile }) {

  const appState = useContext(AppStateContext);
  const mapLoaded = _.get(appState, "mapLoaded");
  const viewport = _.get(appState, "viewport");
  const setViewport = appState.actions.setViewport;
  const data = _.get(appState, "data");
  const mapClickDisabled = useRef(false);

  const [formValues, setFormValues] = useState({
    isPaused: false,
    sliderValue: 0,
    startDate: moment('2020-03-01'),
    // endDate: moment().subtract(79, 'days'),
    endDate: moment().subtract(1, 'days'),
    severityLevel: 'confirmed25'
  });
  const [seconds, setSeconds] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const mapRef = useRef(null);

  useInitialZoom(appState, viewport, setViewport, isMobile);

  const intervalDuration = 500
  const severityFilter = formValues.severityLevel.includes("confirmed") ?
    `filterSymbol=eq&filterName=${formValues.severityLevel}&filterValue=1`
    : `filterSymbol=gt&filterName=${formValues.severityLevel}&filterValue=0`
  const regionTimeseries = useQuery(
    ["regionTimeseriesReusable", {
      path: appState.path,
      dataType: 'new',
      severityLevel: formValues.severityLevel,
      queryString: `${severityFilter}&limit=5000&statName=confirmed&statName=${formValues.severityLevel}&regionType=county&day=${formValues.startDate.format('YYYY-MM-DD')}&day=${formValues.endDate.format('YYYY-MM-DD')}`
    }],
    getRegionTimeseriesReusable
  );

  let queryDate = moment(formValues.startDate).add(seconds, 'days')
  let queryDateFormatted = queryDate.format('MM-DD-YYYY')
  let newMarkers = []
  // console.log(`**************QDF ${queryDateFormatted} MAP LOADED? ${mapLoaded}***************************`, regionTimeseries.data)
  if(regionTimeseries.data && mapLoaded && regionTimeseries.data[queryDateFormatted]) {
    newMarkers =  regionTimeseries.data[queryDateFormatted]
  }
  if (queryDateFormatted === formValues.endDate.format('MM-DD-YYYY')) {
    // console.log(`hit end of date range, clearing interval in region loop ID ${intervalId}`)
    clearInterval(intervalId)
  }


  useEffect(() => {
    if(mapLoaded) {
      setIntervalId(setInterval(() => {
        // console.log(`************SETTING INTERVAL ON LOAD ${intervalId} ************`, formValues)
        setSeconds(seconds => seconds + 1);
      }, intervalDuration));
      return () => {
        // console.log(`*************CLEARING INTERVAL IN THE LOAD ${intervalId} **************`)
        clearInterval(intervalId);
      }
    }
  }, [mapLoaded]);
  return (
    <div className="absolute inset-0">
      <ReactMapGL
        {...viewport}
        interactive={false}
        width="100%"
        height="100%"
        mapStyle={customSharecareStyle}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        ref={(m) => (mapRef.current = m)}
        onLoad={(e) => {
          const map = e.target;
          appState.actions.setMapLoaded(true);
        }}
      >
        <div className="absolute bottom-0 left-0 ml-2 mb-10">
          <NavigationControl showCompass={false} />
        </div>
        <MapMarkers
          confirmedOnly={true}
          zoom={viewport.zoom}
          markerData={newMarkers}
          setMapClickDisabled={(val) => {
            mapClickDisabled.current = val;
          }}
        />
      </ReactMapGL>
      <div
        style={isMobile? {marginTop: 120} : {}}
        className={
          isMobile
            ? 'flex fixed top-0 w-full justify-center'
            : 'flex fixed bottom-0 w-full justify-center'
        }
      >
        <CountyMapControls
          formValues={formValues}
          formValuesCallback={(formValues) => {
            if (!formValues.isPaused) {
              setSeconds(formValues.sliderValue);
              setFormValues({ ...formValues });
              setIntervalId(
                setInterval(() => {
                  // console.log('************SETTING INTERVAL CLICKING PLAY************')
                  setSeconds((seconds) => seconds + 1);
                }, intervalDuration)
              );
              return () => {
                // console.log(`*************CLEARING INTERVAL IN THE PRESS PLAY ${intervalId} **************`)
                clearInterval(intervalId);
              };
            } else {
              // console.log(`*************CLEARING INTERVAL AS I PAUSED  AND SETTING FORMVALUES ${intervalId} MAP LOADED ${mapLoaded}**************`, formValues)
              setFormValues({ ...formValues });
              clearInterval(intervalId);
              setSeconds(formValues.sliderValue);
            }
          }}
          seriesDay={queryDate}
          seconds={seconds}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
}
