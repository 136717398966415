import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import AppStateContext from "./contexts/AppState";

import DesktopMapPage from "./pages/desktop/Map.js";
import DesktopChartsPage from "./pages/desktop/Charts.js";

import MobileMapPage from "./pages/mobile/Map.js";
import MobileChartsPage from "./pages/mobile/Charts.js";

const PAGES = {
  desktop: { map: DesktopMapPage, charts: DesktopChartsPage },
  mobile: { map: MobileMapPage, charts: MobileChartsPage },
};

const useViewport = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleWindowResize = _.throttle(() => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    }, 100);

    window.addEventListener("resize", handleWindowResize);

    return () => {
      handleWindowResize.cancel();
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return dimensions;
};

function Page({ type, appState }) {
  //TODO: refactor how to load different maps, tabs, etc
  const page = appState.page === "usCountyAnimation" ? "map" : _.get(appState, "page")
  const PageComponent = _.get(PAGES, [type, page]);
  if (PageComponent) {
    return <PageComponent />;
  }
  return null;
}

function DesktopApp() {
  const appState = useContext(AppStateContext);
  const mapRightPanelHidden = _.get(appState, "mapRightPanelHidden");

  return (
    <div
      className={`font-sans overflow-hidden flex flex-col min-h-0 h-screen transition-opacity duration-100 ease ${
        mapRightPanelHidden ? "right-panel-hidden" : ""
      } ${appState.mapLoaded ? "opacity-100" : "opacity-0"}`}
    >
      <Page type={"desktop"} appState={appState} />
    </div>
  );
}

function MobileApp() {
  const appState = useContext(AppStateContext);
  return (
    <div className="font-sans mobile">
      <Page type="mobile" appState={appState} />
    </div>
  );
}

export default function App() {
  const { width, height } = useViewport();
  return width > 768 ? <DesktopApp /> : <MobileApp />;
}
