import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import AppStateContext from '../contexts/AppState';
import { useQuery } from 'react-query';
import { getCountiesTimeseries, getRegion } from '../domain/api';
import _ from 'lodash';
import Select from 'react-select';
import MobilityChart from './MobilityChart';
import CountyChart from './CountyChart';
import GlobalChartFrame from './GlobalChartFrame';
import {
  chartChoices,
  chartOptions,
  chartUSOptions,
  chartStateOptions,
  chartGlobalOptions,
  filterCases,
  graphOptions,
  optionConfig,
  stateExclusionList,
} from '../chartUtil';

const ChartFrame = ({ chartName, chartView }) => {
  const appState = useContext(AppStateContext);

  const new_Path = [];
  Object.assign(new_Path, appState.path);

  const [Usstate, setUsState] = useState(
    appState.path.length > 1 && appState.path.includes('US')
      ? { label: appState.path[1], value: appState.path[1] }
      : { label: 'Select State', value: 'All' }
  );
  const [county, setCounty] = useState(
    appState.path.length === 3 && appState.path.includes('US')
      ? { label: appState.path[2], value: appState.path[1] }
      : { label: 'Select County', value: 'All' }
  );
  const [counties, setCounties] = useState([]);
  const [options, setOptions] = useState((chartName === 'MobilityChart'
  || (chartName === 'GlobalChartFrame' && chartView === 'country'))? chartUSOptions : chartGlobalOptions);
  const [option, setOption] = useState({ label: 'Confirmed', value: 'Confirmed' });
  const [path, setPath] = useState(appState.path.length > 0 && appState.path.includes('US') ? new_Path : ['US']);
  const [fetchDataType, setFetchDataType] = useState('cumulative');
  const [highlightPath, setHighlightPath] = useState(new_Path);
  const [country, setCountry] = useState(null);
  const [choice, setChoice] = useState(chartChoices[0]);
  const [graphType, setGraphType] = useState(graphOptions[0]);
  const [showLegend, setShowLegend] = useState(false);
  const [showTopBar, setshowTopBar] = useState(false);
  const [showCountyDropdown, setShowCountyDropdown] = useState(false);
  const [stateInitial, setStateInitial] = useState(null);

  const regionChart = useQuery(['regionChart', { path: ['US'], dataType: fetchDataType }], getRegion);
  const topCountiesData = useQuery(
    [
      'countiesData',
      {
        dataType: fetchDataType,
        option: optionConfig[option.value].param,
      },
    ],
    getCountiesTimeseries
  );

  // Filter states, countries and counties_data
  const countries = useMemo(() => {
    let subData = regionChart.data ? regionChart.data['subStats'] : [];
    let sortedSubStats = _.sortBy(subData, (s) => {
      return _.get(s, ['stat', optionConfig[option.value].param], 0);
    });
    let count = filterCases(sortedSubStats, 100, option, choice);
    let final = [];
    _.forEach(count, (value) => {
      let temp_obj = {};
      temp_obj['value'] = value;
      temp_obj['label'] = value;
      final.push(temp_obj);
    });
    return final;
  }, [regionChart.data, option, choice]);

  const states = useMemo(() => {
    let subData = regionChart.data ? regionChart.data['subStats']['US']['subStats'] : [];
    if (!subData) return [];
    let sortedSubStats = _.sortBy(subData, (s) => {
      return _.get(s, ['stat', optionConfig[option.value].param], 0);
    });
    let count =
      chartName === 'GlobalChartFrame' && chartView === 'country'
        ? filterCases(sortedSubStats, 100, option, choice)
        : _.sortBy(Object.keys(subData));
    let final =
      (chartName === 'GlobalChartFrame' && (chartView === 'state' || chartView === 'county')) ||
      (chartName === 'MobilityChart' && chartView === 'state')
        ? []
        : [{ value: 'All', label: 'Select State' }];
    _.forEach(count.slice(1), (value) => {
      if (stateExclusionList.indexOf(value) === -1 && !(value.startsWith('Out of') || value.startsWith('Unassigned'))) {
        let temp_obj = {};
        temp_obj['value'] = value;
        temp_obj['label'] = value;
        final.push(temp_obj);
      }
    });
    return final;
  }, [regionChart.data, choice, chartView]);

  const filterTopCounties = (value, tempList) => {
    if (topCountiesData.data && topCountiesData.data.subRegions) {
      let filtered = [];
      _.forEach(topCountiesData.data.subRegions, (value) => {
        if (value.region.split(':')[2]) filtered.push(value.region.split(':')[2]);
      });
      return _.filter(tempList, (o) => {
        return !filtered.includes(o);
      });
    }
  };

  // For top state counties
  const countiesData = useMemo(() => {
    let subData = regionChart.data ? regionChart.data['subStats']['US']['subStats'] : [];
    if (!subData) return [];
    let final = {};
    _.forEach(subData, (value, key) => {
      if (value.subStats !== null) {
        let sortedSubStats = _.sortBy(value.subStats, (s) => {
          return _.get(s, ['stat', optionConfig[option.value].param], 0);
        });
        let tempList =
          chartName === 'GlobalChartFrame' && chartView === 'state'
            ? filterCases(sortedSubStats, 20, option, choice)
            : _.sortBy(Object.keys(value.subStats));
        //
        if (chartName === 'CountyChart' && chartView === 'country') {
          let data = filterTopCounties(value, tempList);
          if (data) tempList = data;
        }

        let tempList2 = [{ label: 'Select County', value: 'All' }];
        _.forEach(tempList, (value) => {
          if (!(value.startsWith('Out of') || value.startsWith('Unassigned'))) {
            let tempObj = {};
            tempObj['value'] = value;
            tempObj['label'] = value;
            tempList2.push(tempObj);
          }
        });
        final[key] = tempList2;
      }
    });
    return final;
  }, [states, option, regionChart.data, chartView, topCountiesData.data]);

  const updateMapPath = (newPath) => {
    let mapPath = appState.path;
    _.forEach(mapPath, (value) => {
      appState.actions.goBack();
    });
    _.forEach(newPath, (value, index) => {
      appState.actions.drillDown(value);
    });
  };
  const firstUpdate = useRef(true);
  useEffect(() => {
    if ((chartView && !firstUpdate.current) || (chartView === 'state' && chartName === 'MobilityChart')) {
      setFetchDataType('cumulative');
      if (chartView === 'Global') {
        setCountry('');
        setUsState(states[0]);
        setHighlightPath([]);
        setPath([]);
        updateMapPath([]);
        setOptions(chartGlobalOptions);
        setOption(chartGlobalOptions[1])
      }

      if (chartView === 'state') {
        let newPath = ['US'];
        if (states.length > 0) {
          if (Usstate.value === 'All') {
            setUsState(states[0]);
            newPath.push(states[0].value);
            setHighlightPath(newPath);
            setPath(newPath);
            setShowCountyDropdown(true);
            if (chartName === 'MobilityChart') updateMapPath(newPath);
          } else {
            newPath.push(Usstate.value);
            setHighlightPath(newPath);
            setPath(newPath);
            setShowCountyDropdown(true);
            if (chartName === 'MobilityChart') updateMapPath(newPath);
          }
          setOption(chartOptions[0])
          setOptions(chartOptions)
        }
      }

      if (chartView === 'country') {
        setCountry('');
        setUsState(states[0]);
        setHighlightPath(['US']);
        setPath(['US']);
        setShowCountyDropdown(false);
        setOption(chartUSOptions[1])
        updateMapPath(['US']);
        if (chartName !== 'CountyChart') {
          setOptions(chartUSOptions)
        }
      }
    }
    if (firstUpdate.current) firstUpdate.current = false;
  }, [chartView]);

  useEffect(()=>{
    if(chartName === 'CountyChart' && chartView === 'country'){
      setCountry('');
      setUsState(states[0]);
      setHighlightPath(['US']);
      setPath(['US']);
      setShowCountyDropdown(false);
      updateMapPath(['US']);
      }
  }, [chartView]);

  useEffect(() => {
    if (Usstate.value === 'All') {
      setCounties([]);
      setCounty([]);
    } else setCounties(countiesData[Usstate.value]);
  }, [Usstate]);

  let updateUsState = (selection) => {
    let value = selection.value;
    setUsState(selection);
    let newPath = ['US'];
    if (value !== 'All') newPath.push(value);
    setHighlightPath(newPath);
    setPath(newPath);
    setCounties(countiesData[value]);
    if (countiesData[value]) setCounty(countiesData[value][0]);
    if (value === 'All') {
      setOptions(chartUSOptions);
    } else {
      if(chartView === 'country' && chartName !=='CountyChart') setOptions(chartStateOptions);
      else setOptions(chartOptions);
      if (option.value === 'Active' || option.value.indexOf('Recovered') !== -1) {
        setOption({ label: 'Confirmed', value: 'Confirmed' });
        setFetchDataType('cumulative');
      }
    }
    if ((chartView === 'state' || chartView === 'county') && chartName === 'MobilityChart')  updateMapPath(newPath);
  };

  let updateCounty = (selection) => {
    let value = selection.value;
    setCounty(selection);
    if (path.length === 3) path.pop();
    if (value !== 'All') path.push(value);
    if (highlightPath.length === 3) highlightPath.pop();
    if (value !== 'All') highlightPath.push(value);
    if ((chartView === 'state' || chartView === 'county') && chartName === 'MobilityChart') updateMapPath(path);
  };

  let updateOption = (selection) => {
    let value = selection.value;
    setOption(selection);
    if (value.includes('Daily')) setFetchDataType('new');
    else setFetchDataType('cumulative');
  };

  let updateCountry = (selection) => {
    setCountry(selection);
    setHighlightPath([selection.value]);
  };

  let updateChoice = (selection) => {
    setChoice(selection);
    setCountry('');
    setCounty('');
    if (chartView === 'country' && chartName==="GlobalChartFrame") setUsState({ label: 'Select State', value: 'All' });
  };

  let updateGraphAxis = (selection) => {
    setGraphType(selection);
  };

  let chartScroll = null;
  const onScroll = () => {
    const scrollTop = chartScroll.scrollTop;
    if (scrollTop > 500) setshowTopBar(true);
    else setshowTopBar(false);
    if (scrollTop > 1050) setShowCountyDropdown(true);
    else setShowCountyDropdown(false);
  };

  return (
    <>
      <div className={`mt-5 flex`}>
        <div className='drop'>
          {chartName === 'GlobalChartFrame' && chartView === 'Global' && (
            <Select
              isSearchable={false}
              key='country'
              value={country}
              options={countries}
              onChange={updateCountry}
              placeholder='Select Country'
              className='basic-single wd-170'
              classNamePrefix='select'
            />
          )}
          {chartView !== 'Global' && (
            <>
              <Select
                isSearchable={false}
                key='states'
                value={Usstate}
                options={states}
                onChange={updateUsState}
                placeholder='Select State'
                className='basic-single'
                classNamePrefix='select'
                isDisabled={chartView === 'Global'}
              />
              {(showCountyDropdown || chartView === 'state' || chartView === 'county') &&
                counties &&
                counties.length - 1 > 0 && (
                  <Select
                    isSearchable={false}
                    key='counties'
                    value={county}
                    options={counties}
                    onChange={updateCounty}
                    placeholder='Select County'
                    className='basic-single'
                    classNamePrefix='select'
                  />
                )}
            </>
          )}
          {chartView === 'country' && chartName === 'CountyChart' && counties && counties.length - 1 > 0 && (
            <Select
              isSearchable={false}
              key='counties'
              value={county}
              options={counties}
              onChange={updateCounty}
              placeholder='Select County'
              className='basic-single'
              classNamePrefix='select'
            />
          )}
          <Select
            isSearchable={false}
            key='options'
            value={option}
            options={options}
            onChange={updateOption}
            placeholder='Select Option'
            className='basic-single wd-170'
            classNamePrefix='select'
            maxMenuHeight="600"
          />
          {chartName !== 'MobilityChart' && (
            <>
              <Select
                isSearchable={false}
                key='choice'
                value={choice}
                options={chartChoices}
                onChange={updateChoice}
                placeholder='Select Choice'
                className='basic-single wd-100'
                classNamePrefix='select'
              />
              <Select
                isSearchable={false}
                key='graphType'
                value={graphType}
                options={graphOptions}
                onChange={updateGraphAxis}
                placeholder='Select Option'
                className='basic-single wd-130'
                classNamePrefix='select'
              />
            </>
          )}
        </div>
      </div>
      <div
        className='hide-native-scrollbar'
        ref={(comp) => (chartScroll = comp)}
        onScroll={onScroll}
        style={{ overflow: 'auto', overflowX: 'hidden' }}
      >
        {chartName === 'MobilityChart' && (
          <div>
            <MobilityChart
              option={option.value}
              isMobile={false}
              path={path}
              fetchDataType={fetchDataType}
              chartView={chartView}
            />
          </div>
        )}
        {chartName === 'GlobalChartFrame' && (
          <GlobalChartFrame
            isMobile={false}
            states={states}
            countiesData={countiesData}
            regionChart={regionChart}
            chartView={chartView}
            parentUsstate={Usstate}
            parentCounties={counties}
            parentCounty={county}
            parentOption={option.value}
            parentCountry={country}
            parentChoice={choice}
            parentAxis={graphType}
            parentChoiceUpdate={updateChoice}
            parentAxisUpdate={updateGraphAxis}
            highlightPath={highlightPath}
            setShowLegend={setShowLegend}
            showLegend={showLegend}
            showTopBar={showTopBar}
            fetchDataType={fetchDataType}
          />
        )}
        {chartName === 'CountyChart' && (
          <div className='mt-10'>
            <CountyChart
              isMobile={false}
              option={option.value}
              states={states}
              topCountiesData={topCountiesData}
              regionChart={regionChart}
              chartView={chartView}
              parentUsstate={Usstate}
              parentCounties={counties}
              parentCounty={county}
              parentCountry={country}
              parentChoice={choice}
              parentAxis={graphType}
              parentChoiceUpdate={updateChoice}
              parentAxisUpdate={updateGraphAxis}
              highlight={county}
              highlightPath={highlightPath}
              showLegend={showLegend}
              fetchDataType={fetchDataType}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default ChartFrame;
