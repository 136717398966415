import React, { useMemo, useEffect, useState } from "react";
import _ from "lodash";
import produce from "immer";
import { hexToRgb } from "../util";

function getRandomId() {
  return Date.now() + (Math.random() * 100000).toFixed();
}

function getCirclesData(total, data, radius) {
  const sortedData = _.orderBy(data, ["originalValue"], ["desc"]);
  const max = total; //_.get(sortedData, "[0].value", 0);

  return _.reduce(
    data,
    (acc, datum, idx) => {
      const originalValue = Math.max(0, datum.originalValue);
      const value = Math.max(0, datum.value);
      const dataPercentage = isNaN(originalValue / max)
        ? 0
        : originalValue / max;

      acc.push({
        value: value,
        radius: Math.round(dataPercentage * radius),
        color: datum.color,
      });
      return acc;
    },
    []
  );
}

function zeroifyData(data) {
  return produce(data, (draftData) => {
    draftData.forEach((d) => {
      d.value = 0;
    });
  });
}

function CircleChart({ dimension, strokeWidth, transitionDelay, data, total }) {
  const [mounted, setMounted] = useState(false);

  /* On mount generate id for this instance. We need it because
  SVG's defs are global, so we get clashes which was resulting
  with black fills (instead of semi transparent gradient) in some
  cases */
  const [id] = useState(getRandomId());

  useEffect(() => {
    setMounted(true);
  }, []);

  const width = dimension;
  const height = dimension;
  const c = dimension / 2;
  const radius = dimension / 2 - strokeWidth;
  const cir = 2 * Math.PI * radius;
  const circlesData = useMemo(
    () => getCirclesData(total, mounted ? data : zeroifyData(data), radius),
    [data, total, radius, mounted]
  );
  const sum = _.reduce(
    data,
    (acc, d) => {
      return acc + d.value;
    },
    0
  );

  let fullColorIndex = -1;
  for (let i = 0; i < data.length; i++) {
    if (data[i].type === "dead") {
      fullColorIndex = i;
      break;
    }
  }

  return (
    <div className="animated-circle">
      <svg width={width} height={height}>
        <defs>
          {_.map(circlesData, (circle, key) => {
            const rgbColor = circle.color
              ? hexToRgb(circle.color)
              : { r: 0, g: 0, b: 0 };
            return (
              <radialGradient
                key={key}
                id={`bg-${id}-${key}`}
                cx="50%"
                cy="50%"
                r="50%"
              >
                <stop
                  stopColor={`rgb(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b})`}
                  stopOpacity="0"
                  offset="0"
                />
                <stop
                  stopColor={`rgb(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b})`}
                  stopOpacity="0"
                  offset="0.45"
                />
                <stop
                  stopColor={`rgb(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b})`}
                  stopOpacity="0.25"
                  offset="1"
                />
              </radialGradient>
            );
          })}
        </defs>
        {_.map(circlesData, (circle, key) => {
          return (
            <circle
              cx={c}
              cy={c}
              r={Math.min(circle.radius, radius)}
              fill={
                key === fullColorIndex ? circle.color : `url(#bg-${id}-${key})`
              }
              strokeWidth={strokeWidth}
              stroke={circle.color}
              key={key}
              style={{
                opacity: circle.value ? 1 : 0,
                transform: `scale(${circle.value ? 1 : 0.8})`,
              }}
            />
          );
        })}
      </svg>
    </div>
  );
}

export default React.memo(CircleChart);
