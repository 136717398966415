import React, { useContext, useMemo } from "react";
import _ from "lodash";
import AppStateContext from "../contexts/AppState";
import StatsTable from "./StatsTable";
import SingleStat from "./SingleStat";
import { ChevronDown, ChevronUp, ChevronLeft } from "./icons";

export default function ({ isMobile }) {
  const appState = useContext(AppStateContext);
  const data = _.get(appState, "data");

  if (_.isEmpty(data)) return null;

  return (
    <div
      className={`flex flex-col min-h-0 ${!isMobile ? "mr-6" : "ml-2 mr-2"}`}
    >
      <div className="flex min-h-0 justify-end">
        {data.subStats ? (
          <StatsTable isMobile={isMobile} />
        ) : (
          <SingleStat isMobile={isMobile} />
        )}
      </div>
    </div>
  );
}
