import React, { useContext, useState, useEffect } from 'react';
import { Mobile as TopBar } from '../../components/TopBar';
import RegionTitle from '../../components/RegionTitle';
import AppStateContext from '../../contexts/AppState';
import { Globe, List, BarChart, Play } from '../../components/icons';
import _ from 'lodash';
import Map from '../../components/Map';
import Stats from '../../components/Stats';
import Chart from './Charts.js';
import { formatNumber } from '../../util';
import statsConfig from '../../domain/stats';
import {default as USCountyMap} from '../../components/maps/us-cases-by-county/Map';

function MobileTab({ isActive, Icon, label, onClick }) {
  return (
    <button
      onClick={onClick}
      className={`text-white flex items-center justify-center py-1 font-semibold rounded transition-color duration-100 transition-ease ${
        isActive ? 'bg-sharecare-darkgreen' : 'bg-sharecare-gray'
      }`}
    >
      <Icon className='h-6 y-6 mr-2' />
    </button>
  );
}

function MapStats({ appState }) {
  const filteredStatsConfig = _.filter(statsConfig, (stat) => _.get(appState, ['stats', stat.id]));
  const data = _.get(appState, 'data');
  let statLabel = null;
  return (
    <div className='mt-2 relative text-white flex ml-6' key={_.get(appState, 'data.name')}>
      {_.map(filteredStatsConfig, (stat) => {
        statLabel = stat.label;
        return (
          <div key={stat.id} className={`flex-grow text-sharecare-${stat.color} uppercase leading-tight`}>
            <div className='text-sm font-extrabold'>{formatNumber(_.get(data, ['stat', stat.id]), true)}</div>
            <div className='text-2xs font-semibold flex items-start leading-none'>{statLabel}</div>
          </div>
        );
      })}
    </div>
  );
}

function ChartStats({ appState }) {
  const filteredStatsConfig = _.filter(statsConfig, (stat) => _.get(appState, ['stats', stat.id]));
  return (
    <div className='mt-2 relative text-white flex ml-2'>
      {_.map(filteredStatsConfig, (stat) => {
        return (
          <div key={stat.id} className={`text-white leading-tight mr-4 flex items-center`}>
            <div className={`bg-sharecare-${stat.color} h-4 w-4 rounded-full mr-2`} />
            <div className='text-sm font-semibold flex items-start leading-none'>{stat.label}</div>
          </div>
        );
      })}
    </div>
  );
}

function MobileInteractionNotice() {
  const [shouldRender, setShouldRender] = useState(!localStorage.getItem('sharecare-mobile-notice'));
  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   setShouldRender(false);
    //   localStorage.setItem("sharecare-mobile-notice", true);
    // }, 3000);
    // return () => {
    //   clearTimeout(timeout);
    // };
  }, []);
  if (!shouldRender) {
    return null;
  }
  return (
    <div className='bg-sharecare-yellow-semi text-white rounded absolute bottom-0 right-0 left-0 ml-12 mr-3 mb-10 text-center text-sm font-semibold leading-tight shadow-xl'>
      <div
        className='float-right mr-3 mt-3 text-xl'
        onClick={() => {
          setShouldRender(false);
          localStorage.setItem('sharecare-mobile-notice', true);
        }}
      >
        X
      </div>
      <br />
      <div className='p-3 pt-6 pb-6'>
        Please interact with our maps
        <br />
        by tapping on them
      </div>
    </div>
  );
}

export default function MapPage() {
  const appState = useContext(AppStateContext);
  //TODO: refactor how to load different maps, tabs, etc
  const tab = _.get(appState, ['mobileTab', _.get(appState, "page")]);
  const page = _.get(appState, "page");
  const hasChart = !!_.get(appState, 'path').length;
  const isMobileBrowser = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  return (
    <>
      {tab === 'map' || tab === "usCountyAnimation" || page === "usCountyAnimation" ? (
        <>
          <div className='absolute mobile-map-wrap'>
            {tab === "usCountyAnimation" || page === "usCountyAnimation" ? <USCountyMap isMobile={true} /> : <Map isMobile={true} />}
          </div>
          {tab != "usCountyAnimation"  || page === "usCountyAnimation" && <div className='mobile shadow-top'></div>}
          {isMobileBrowser() && <MobileInteractionNotice />}
        </>
      ) : null}
      <div className='relative mobile'>
        <div className='fixed-header '>
          <TopBar />
          <div className='mx-4 grid gap-2 mt-2 grid-cols-4'>
            <MobileTab
              label='Map'
              Icon={Globe}
              isActive={tab === 'map'}
              onClick={() => appState.actions.setMobileTab('map', 'map')}
            />
            <MobileTab
              label='Data'
              Icon={List}
              isActive={tab === 'stats'}
              onClick={() => appState.actions.setMobileTab('map', 'stats')}
            />
            <MobileTab
              label="US Map"
              Icon={Play}
              onClick={() => {
                appState.actions.setPath(["US"])
                appState.actions.setMobileTab('map', 'usCountyAnimation')
              }}
              isActive={tab === "usCountyAnimation" || page === "usCountyAnimation"}
            />
            <MobileTab
              label='Graph'
              Icon={BarChart}
              isActive={tab === 'chart'}
              onClick={() => appState.actions.setMobileTab('map', 'chart')}
            />
          </div>
        </div>
        {tab !== "usCountyAnimation" && page !== "usCountyAnimation" && (
          <div className='flex flex-row text-xl' style={{ marginTop: '6rem' }}>
            {tab !== 'chart' ? <RegionTitle className='ml-4 mt-4' isMobile={true} /> : null}
            {tab === 'map' ? (
              <div className='flex-grow mt-1'>
                <MapStats appState={appState} />
              </div>
            ) : null}
          </div>
        )}
      </div>
      {tab === 'stats' ? (
        <div className='mt-4'>
          <Stats isMobile={true} />
        </div>
      ) : null}
      {tab === 'chart' ? (
        <div className='h-64 mt-2 pl-2 pr-2'>
          {/* <ChartStats appState={appState} /> */}
          <Chart />
        </div>
      ) : null}
    </>
  );
}
