import axios from "axios";
import _ from "lodash";
import turfBBox from "@turf/bbox";
import turfBBoxPolygon from "@turf/bbox-polygon";
import turfBooleanContains from "@turf/boolean-contains";
import bboxes from "./bboxes.json";
import usaPolygonBoundaries from "./usaPolygonBoundaries";
import adminData from "./adminData";
import { getRegionMinMax, getTimeseriesMinMax } from "../util"
import statNames from "./stats";

const API_URL = "https://api.sharecare.com/corona-summarization";

function unpackAdminData(data, includeIso3166) {
  return _.reduce(
    data,
    (acc, val) => {
      const payload = unpackAdminData(_.get(val, "children"), false) || {};
      payload.__featureId = val.featureId;
      for (let i = 0; i < val.names.length; i++) {
        if (includeIso3166) {
          _.set(acc, [val.iso_3166_1, val.names[i]], payload);
        } else {
          acc[val.names[i]] = payload;
        }
      }
      return acc;
    },
    {}
  );
}

const unpackedAdminData = unpackAdminData(adminData, true);

function makePoint(coordinates, properties) {
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: coordinates,
    },
    properties: properties || {},
  };
}

function makeLineString(coordinates, properties) {
  return {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: coordinates,
    },
    properties: properties || {},
  };
}

const bboxesPolygons = _.map(bboxes, (bbox) => {
  return { polygon: turfBBoxPolygon(bbox), bbox: bbox };
});

function createPerCapitaStats(stat, population) {
  return _.mapValues(stat, (statValue, statKey) => {
    return _.find(statNames, ['id', statKey]) ? Math.round((statValue / population) * 100000) : statValue
  })
}
function createRegionObject(data, name, mapJoinValue, minMax) {
  const population = data.region.population || Infinity
  const regionStats = { ...data.stat }
  //Add missing stat properties so sorting works properly
  statNames.map(stat => {
    if(regionStats[stat.id] === undefined) regionStats[stat.id] = 0
  })
  return {
    region: {
      ...data.region,
      key: data.region.key,
      state: "",
      county: ""
    },
    perCapitaStat: createPerCapitaStats(data.stat, population),
    stat: regionStats,
    type: data.region.type,
    subStats: {},
    sortableName: (name + "").toLowerCase(),
    name: name,
    mapJoinValue: mapJoinValue,
    siblingTotalMin: minMax.min,
    siblingTotalMax: minMax.max
  }
}

function postProcessGlobalData(processedData) {
  const usCountyMapMarkers = []
  const countryMinMax = getRegionMinMax(processedData.subStats, true)
  _.map(processedData.subStats, country => {
    processedData.subStats[country.name].perCapitaSiblingTotalMax = countryMinMax.max
    processedData.subStats[country.name].perCapitaSiblingTotalMin = countryMinMax.min
    const stateMinMax = getRegionMinMax(country.subStats, true)
    _.map(country.subStats, state => {
      processedData.subStats[country.name].subStats[state.name].perCapitaSiblingTotalMax = stateMinMax.max
      processedData.subStats[country.name].subStats[state.name].perCapitaSiblingTotalMin = stateMinMax.min
      const countyMinMax = getRegionMinMax(state.subStats, true)
      _.map(state.subStats, county => {
        // TODO: PER CAPITA STATS, REFACTOR ALL THiS DATA IS NOT NEEDED
        county.region.latitude && county.region.longitude &&
          usCountyMapMarkers.push({
            key: _.get(county, "region.key"),
            name: _.get(county, "name"),
            mapJoinValue: _.get(county, "mapJoinValue"),
            countryCode: _.get(county, "region.code2"),
            latitude: _.get(county, "region.latitude"),
            longitude: _.get(county, "region.longitude"),
            activeOriginal: _.get(county, "stat.active"),
            recoveredOriginal: _.get(county, "stat.recovered"),
            deadOriginal: _.get(county, "stat.dead"),
            confirmedOriginal: _.get(county, "stat.confirmed"),
            active: _.get(county, "stat.active"),
            recovered: _.get(county, "stat.recovered"),
            dead: _.get(county, "stat.dead"),
            confirmed: _.get(county, "stat.confirmed"),
            total: _.get(county, "stat.confirmed"),
          });
        processedData.subStats[country.name].subStats[state.name].subStats[county.name].perCapitaSiblingTotalMax = countyMinMax.max
        processedData.subStats[country.name].subStats[state.name].subStats[county.name].perCapitaSiblingTotalMin = countyMinMax.min
      })
    })
  })
  processedData.subStats['US'].usCountyMarkers = usCountyMapMarkers
}

function processGlobalData(globalData) {
  let processedData = { stat: globalData.stat, subStats: {} }
  let mapJoinValue = null
  const countryMinMax = getRegionMinMax(globalData.countryStats)
  globalData.countryStats.map(country => {
    const countryName = country.region.country
    const countryCode = country.region.code2 || ""
    processedData.subStats[countryName] = createRegionObject(country, countryName, countryCode, countryMinMax)
    const countryMapJoins = unpackedAdminData[countryCode] || []
    const stateMinMax = getRegionMinMax(country.subRegionStats)
    country.subRegionStats.map(state => {
      const stateName = state.region.state
      countryMapJoins[stateName] ? mapJoinValue = countryMapJoins[stateName].__featureId : stateName
      processedData.subStats[countryName].subStats[stateName] = createRegionObject(state, stateName, mapJoinValue, stateMinMax)
      processedData.subStats[countryName].subStats[stateName].region.state = stateName
      const countyMinMax = getRegionMinMax(state.subRegionStats)
      state.subRegionStats.map(county => {
        const countyName = county.region.county
        countryMapJoins[stateName] && countryMapJoins[stateName][countyName] ? mapJoinValue = countryMapJoins[stateName][countyName].__featureId : countyName
        processedData.subStats[countryName].subStats[stateName].subStats[countyName] = createRegionObject(county, countyName, mapJoinValue, countyMinMax)
        processedData.subStats[countryName].subStats[stateName].subStats[countyName].region.county = countyName
        processedData.subStats[countryName].subStats[stateName].subStats[countyName].region.state = stateName
      })
    })
  })
  postProcessGlobalData(processedData)
  return {
    ...processedData,
    type: "Global",
    name: "Global",
  }
}

function processRegionTimeSeries(data, chart_view) {
  let key = ""
  if (chart_view  === "Global")
      key = "0"
  if (chart_view  === "country")
    key = "1"
  if (chart_view  === "state" || chart_view  === "county")
    key = "2"
  let processed_region = {};
  _.forEach(data, (value)=>{
    processed_region[value["data"]["region"].split(":")[key]] = value["data"]
  });
  return processed_region
}

const CACHE = {};

export function getRegion(key, { dataType = "cumulative" }) {
  const url = `${API_URL}/${dataType}`;
  if (!CACHE[url]) {
    CACHE[url] = axios.get(url).then(({ data }) => {
      return processGlobalData(data);
    });
  }
  return Promise.resolve(CACHE[url]);
}

export function getRegionTimeseries(
  key,
  { path, dataType = "cumulative", option = "", statName="" }
) {
  const cleanPath = _.filter(path, _.identity);
  let URL = `${API_URL}/timeseries/${dataType}/${cleanPath.join("/")}`;
  if (Boolean(statName)){
    URL = `${API_URL}/timeseries/${dataType}/${cleanPath.join("/")}?statName=${statName}&statName=${option}&statName=confirmed&statName=dead&statName=recovered&statName=active`
  }
  if (!cleanPath.length) return Promise.resolve({ stat: [], region: {} });
  return axios
    .get(URL)
    .then(({ data }) => {
      return data;
    });
}

function createConfirmedByDay(timeseriesData, severityLevel) {

  let countyMarkersByDay = {}
  return getRegion('region', {dataType: "cumulative"}).then(regionCumulativeData => {
    const usCountyMarkers = regionCumulativeData.subStats.US.usCountyMarkers
    const regionMinMax = getTimeseriesMinMax(timeseriesData.subRegions)
    timeseriesData.subRegions.map(county => {
      const countyKey = county.region
      const countyMarker = _.find(usCountyMarkers, ['key', countyKey])
      countyMarker && county.stat.map(countyStats => {
        const statDay = countyStats.day
        const confirmed = countyStats.stat.confirmed
        const dimension = severityLevel.includes("confirmed") ?
          (Math.log(confirmed) / Math.log(regionMinMax.max)) * 200
          : countyStats.stat.rnought * 50
        const newMarker = {
          ...countyMarker,
          confirmed: confirmed,
          dimension: dimension,
        }
        !countyMarkersByDay[statDay] ?
          countyMarkersByDay[statDay] = [newMarker]
          :  countyMarkersByDay[statDay].push(newMarker)
      })
    })
    return countyMarkersByDay
  })
}

export function getRegionTimeseriesReusable(
  key,
  { path, dataType = "cumulative", severityLevel = "confirmed25", queryString: queryString = "" }
) {
  const cleanPath = _.filter(path, _.identity);
  const url = `${API_URL}/timeseries/${dataType}/${cleanPath.join("/")}?${queryString}`
  if (!cleanPath.length) return Promise.resolve({ stat: [], region: {} });
  // if(!CACHE[url]) {
  //   CACHE[url] = axios
  //     .get(url)
  //     .then(({data}) => {
  //       return createConfirmedByDay(data)
  //     });
  // }
  // return Promise.resolve(CACHE[url]);
  return axios
    .get(url)
    .then(({data}) => {
      return createConfirmedByDay(data, severityLevel)
    });
}

export function getTopRegionTimeseriesData(key, {path=[], dataType = "cumulative", chart_view="global"}) {
  let apiList = [];
  path.forEach(region => {
    apiList.push(axios.get(`${API_URL}/timeseries/${dataType}/${region.join("/")}?statName=confirmed&statName=dead&statName=recovered&statName=active&statName=hospitalized&statName=tested`));
  });
  return axios
      .all(apiList)
      .then((data) => {
        return processRegionTimeSeries(data, chart_view)
      });
}

export function getCountiesTimeseries(
    key,
    { path, dataType = "cumulative", option = "" }
) {
  // const cleanPath = _.filter(path, _.identity);
  // if (!cleanPath.length) return Promise.resolve({ stat: [], region: "" });
  return axios
      .get(`${API_URL}/timeseries/${dataType}/US/?order=desc&orderBy=${option}&limit=25&regionType=county`)
      .then(({ data }) => {
        return data;
      });
}

function getCountryBBox(area) {
  const coords = makePoint([
    _.get(area, "region.longitude"),
    _.get(area, "region.latitude"),
  ]);
  for (let i = 0; i < bboxesPolygons.length; i++) {
    let cur = bboxesPolygons[i];
    if (turfBooleanContains(cur.polygon, coords)) {
      const [minLng, minLat, maxLng, maxLat] = cur.bbox;
      return [
        [minLng, minLat],
        [maxLng, maxLat],
      ];
    }
  }
}

function getStateBBox(area) {
  const points = _.filter(
    _.map(_.get(area, "subStats"), (subarea) => {
      const latitude = _.get(subarea, "region.latitude");
      const longitude = _.get(subarea, "region.longitude");
      if (latitude || longitude) {
        return [longitude, latitude];
      }
    }),
    _.identity
  );
  if (!points.length) return;
  const [minLng, minLat, maxLng, maxLat] = turfBBox(makeLineString(points));
  return [
    [minLng, minLat],
    [maxLng, maxLat],
  ];
}

/*
POLYGON_BBOX_CACHE, calculateBBox, getPolygonBBox1 and getPolygonBBox
work together to provide smooth map zoom in and zoom out feature based
on MapBox Boundaries.

Polygons that we're using here come from the map, and the map query fns
don't query features that aren't rendered in the viewport. So we cache
on our way down (we always start from the "world" level), to be able to
zoom out correctly when going back
*/

const POLYGON_BBOX_CACHE = {};

function calculateBBox(coords, polygon) {
  const [longitude, latitude] = coords;
  const [minLng, minLat, maxLng, maxLat] = turfBBox(polygon);
  const asBBox = [
    [minLng, minLat],
    [maxLng, maxLat],
  ];
  _.set(POLYGON_BBOX_CACHE, [longitude, latitude], asBBox);
  return asBBox;
}

function getPolygonBBox1(coords, polygons) {
  const point = makePoint(coords);

  if (turfBooleanContains(usaPolygonBoundaries.mainland.geometry, point)) {
    return calculateBBox(coords, usaPolygonBoundaries.mainland.geometry);
  }

  for (let i = 0; i < polygons.length; i++) {
    let cur = polygons[i].geometry;
    if (cur.type === "MultiPolygon") {
      for (let j = 0; j < cur.coordinates.length; j++) {
        let p = { type: "Polygon", coordinates: cur.coordinates[j] };

        if (turfBooleanContains(p, point)) {
          return calculateBBox(coords, cur);
        }
      }
    } else if (cur.type === "Polygon") {
      if (turfBooleanContains(cur, point)) {
        return calculateBBox(coords, cur);
      }
    }
  }
}

export function getPolygonBBox(area, polygons) {
  const longitude = _.get(area, "region.longitude");
  const latitude = _.get(area, "region.latitude");
  const cached = _.get(POLYGON_BBOX_CACHE, [longitude, latitude]);
  if (!_.isUndefined(cached)) {
    return cached;
  }

  if (!polygons) return;

  const coords = [longitude, latitude];

  return getPolygonBBox1(coords, polygons);
}

export function getBBox(area, polygons) {
  if (polygons) return getPolygonBBox(area, polygons);
  // Country resolver probably not needed at this point, but will leave it here
  // to make sure we have an alternative if MapBox boundaries fail us
  if (area.type === "country") return getCountryBBox(area);
  if (area.type === "state") return getStateBBox(area);
}
