import _ from 'lodash';

function mapValueInRange(value, in_min, in_max, out_min, out_max) {
  if (!value) value = 0;
  return ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

function abbreviateNumber(number, chart = false) {
  const tier = (Math.log10(number) / 3) | 0;

  if (tier == 0) return number;

  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  const scaled = number / scale;

  // to return Integer instead of float for chart Label
  const returnScale = chart && !(scaled % 1) ? Math.round(scaled).toString() : scaled.toFixed(1);
  return returnScale + suffix;
}

function numberWithCommas(x) {
  if (!x) return 0;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function formatNumber(x, isMobile) {
  return isMobile ? abbreviateNumber(x) : numberWithCommas(x);
}

function getPercentage(max, num) {
  if (num === 0 || max === 0) return 0;
  return (num / max) * 100;
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

function rgbToHex(r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function clamp(min, max, val) {
  return Math.min(Math.max(min, val), max);
}

function getStatName(isPerCapita = false) {
  return isPerCapita ? 'perCapitaStat' : 'stat';
}

function getRegionMinMax(data, perCapita = false) {
  const statName = getStatName(perCapita)
  const statConfirmedValues = _.map(data, (item) => _.get(item, `${statName}.confirmed`) || 0)
  const max = Math.max.apply(0, statConfirmedValues);
  const min = Math.min.apply(0, statConfirmedValues);
  return { max: max, min: min }
}

function getTimeseriesMinMax(data, perCapita = false) {
  const statName = getStatName(perCapita)
  let min = Infinity
  let max = 0
  data.map(subRegion => {
    subRegion.stat.map(subRegionStat => {
      const numConfirmed = subRegionStat[statName].confirmed
      if (numConfirmed < min)  min = numConfirmed
      else if (numConfirmed > max)  max = numConfirmed
    })
  })
  return { max: max, min: min }
}

const stateExclusionList = [
  'Diamond Princess',
  'Grand Princess',
  'Grand Princess Cruise Ship',
  'Guam',
  'Northern Mariana Islands',
  'Unassigned Location (From Diamond Princess)',
  'Virgin Islands',
  'Wuhan Evacuee',
];

const chartUSOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Confirmed', value: 'Confirmed' },
  { label: 'Deaths', value: 'Deaths' },
  { label: 'Recovered', value: 'Recovered' },
  { label: 'Daily Confirmed', value: 'Daily Confirmed' },
  { label: 'Daily Deaths', value: 'Daily Deaths' },
  { label: 'Daily Recovered', value: 'Daily Recovered' },
];

const chartOptions = [
  { label: 'Confirmed', value: 'Confirmed' },
  { label: 'Deaths', value: 'Deaths' },
  { label: 'Daily Confirmed', value: 'Daily Confirmed' },
  { label: 'Daily Deaths', value: 'Daily Deaths' },
];

const chartChoices = [
  { label: 'Top 10', value: 10 },
  { label: 'Top 25', value: 25 }
];

const lineColorPallete = ['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC', '#C278AA', '#B9C735', '#ED3A94', '#F1997E', '#B2E341', '#FA5AAC']

module.exports = {
  mapValueInRange,
  abbreviateNumber,
  numberWithCommas,
  formatNumber,
  getPercentage,
  rgbToHex,
  hexToRgb,
  clamp,
  getStatName,
  getRegionMinMax,
  getTimeseriesMinMax,
  stateExclusionList,
  chartUSOptions,
  chartOptions,
  chartChoices,
  lineColorPallete
}
