import React, { useContext, useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import Chartjs from 'chart.js';
import colors from '../domain/colors';
import { numberWithCommas, abbreviateNumber } from '../util';
import Legend from './Legend';
import {useQuery} from "react-query";
import {getRegionTimeseries} from "../domain/api";
import { optionConfig } from '../chartUtil';

const MAP_WIDTH_DESKTOP = 1500;
const MAP_WIDTH_MOBILE = 1500;

const MobilityChart = ({option, isMobile, chartView, fetchDataType, path }) => {
  const mobilityChartContainer = useRef(null);
  const [mobilityChartInstance, setMobilityChartInstance] = useState(null);
  const [orientation, setOrientation] = useState('portrait');

  let firstTimeScroll = false;
  let rectangleSet = false;
  useEffect(() => {
    if (path.includes('All')) {
      var index = path.indexOf('All');
      if (index > -1) {
        path.splice(index, 1);
      }
    }
  }, [path]);
  const regionTimeseries = useQuery(
      [
        'regionTimeseries',
        { path: path  ,
          dataType: fetchDataType,
          option: optionConfig[option].param,
          chartView: chartView,
          statName: 'relativemobilityindex'},
      ],
      getRegionTimeseries
  );
  const [chartData, setChartData] = useState(regionTimeseries.data);

  useEffect(() => {
    if (regionTimeseries.data) {
      setChartData(regionTimeseries.data);
    }
  }, [regionTimeseries.data]);

  useEffect(() => {
    if (!firstTimeScroll) {
      if (document.getElementById('areaWrapper')) {
        document.getElementById('areaWrapper').scrollLeft = MAP_WIDTH_DESKTOP+ 200;
        firstTimeScroll = true;
      }
    }
  });

  useEffect(() => {
    if (mobilityChartContainer && mobilityChartContainer.current) {
      const newMobilityChartInstance = new Chartjs(mobilityChartContainer.current, chartConfig);
      setMobilityChartInstance(newMobilityChartInstance);
    }
  }, [mobilityChartContainer]);

  const chartConfig = {
    type: 'bar',
    spanGaps: true,
    data: {
      labels: [],
      datasets: [
        // {
        //   type: 'line',
        //   label: 'Relative Mobility %',
        //   data: [],
        //   yAxisID: 'A',
        //   borderColor: colors.darkgreen,
        //   fill: false,
        //   showLine:false
        // },
        {
          label: 'Confirmed Cases',
          data: [],
          yAxisID: 'A',
          backgroundColor: colors.yellow,
          borderColor: colors.yellow,
          borderWidth: 1,
        },
      ],
    },
    options: {
      legend: {
        display: false,
        position: 'bottom',
      },
      tooltips: {
        enabled: isMobile ? false : true,
        displayColors: false,
        callbacks: {
          label: (tooltipItems, data) => {
            let value =
              tooltipItems.datasetIndex === 1 ? tooltipItems.yLabel + '%' : numberWithCommas(tooltipItems.yLabel);
            return value;
          },
        },
      },
      scales: {
        yAxes: [
          {
            id: 'A',
            type: 'linear',
          //   position: 'left',
          //   ticks: {
          //     beginAtZero: false,
          //     fontColor: 'white',
          //     min: -100,
          //     max: 100,
          //     callback: function (label, index, labels) {
          //       return label + '%';
          //     },
          //   },
          // },
          // {
          //   id: 'B',
          //   type: 'linear',
            position: 'right',
            ticks: {
              beginAtZero: true,
              fontColor: 'white',
              callback: function (label, index, labels) {
                return `${abbreviateNumber(label, true)} `;
              },
            },
            gridLines: {
              display: true,
              color: '#2d2d2d',
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              fontColor: 'white',
              autoSkip: true,
              maxTicksLimit: 24,
            },
          },
        ],
      },
      maintainAspectRatio: false,
      responsive: true,
      animation: {
        duration: 0,
        onProgress: function (animation) {
          // console.log('onProgress');
          // if (rectangleSet === true) {
          //   var copyAWidth = animation.chart.scales['A'].width - 10;
          //   var copyAHeight = animation.chart.scales['A'].height + animation.chart.scales['A'].top + 10;
          //   var sourceCtx = animation.chart.canvas.getContext('2d');
          //   sourceCtx.clearRect(0, 0, copyAWidth, copyAHeight);
          //   var copyBWidth = animation.chart.scales['B'].width;
          //   var copyBHeight = animation.chart.scales['B'].height + animation.chart.scales['B'].top + 10;
          //   var sourceCtx = animation.chart.canvas.getContext('2d');
          //   sourceCtx.clearRect(animation.chart.scales['B'].left, 0, copyBWidth, copyBHeight);
          // }
        },
        onComplete: function (animation) {
          const mapWidth = isMobile ? MAP_WIDTH_MOBILE : MAP_WIDTH_DESKTOP;
          const scale = window.devicePixelRatio;

          var sourceCanvas = animation.chart.canvas;
          // var copyAWidth = animation.chart.scales['A'].width - 10;
          // var copyAHeight = animation.chart.scales['A'].height + animation.chart.scales['A'].top + 10;
          //
          // if (document.getElementById('axis-A')) {
          //   var targetCtx = document.getElementById('axis-A').getContext('2d');
          //
          //   targetCtx.scale(scale, scale);
          //   targetCtx.canvas.width = copyAWidth * scale;
          //   targetCtx.canvas.height = copyAHeight * scale;

          var copyBWidth = animation.chart.scales['A'].width;
          var copyBHeight = animation.chart.scales['A'].height + 10;

          if (document.getElementById('axis-A')) {
            var targetBCtx = document.getElementById('axis-A').getContext('2d');

            targetBCtx.scale(scale, scale);
            targetBCtx.canvas.width = copyBWidth * scale;
            targetBCtx.canvas.height = copyBHeight * scale;
            targetBCtx.canvas.left = animation.chart.scales['A'].left * scale;

            const devWidth = window.innerWidth - 35;
            let bLeft = devWidth - (mapWidth - animation.chart.scales['A'].left);

            targetBCtx.canvas.style.width = `${copyBWidth}px`;
            targetBCtx.canvas.style.height = `${copyBHeight}px`;
            targetBCtx.canvas.style.left = `${bLeft}px`;
            // targetBCtx.drawImage(
            //   sourceCanvas,
            //   animation.chart.scales['B'].left * scale,
            //   0,
            //   copyBWidth * scale,
            //   copyBHeight * scale,
            //   0,
            //   0,
            //   copyBWidth * scale,
            //   copyBHeight * scale
            // );
          }

          // var sourceCtx = sourceCanvas.getContext('2d');
          // Normalize coordinate system to use css pixels.
          // sourceCtx.clearRect(0, 0, copyAWidth * scale, copyAHeight * scale);
          // sourceCtx.clearRect(animation.chart.scales['B'].left, 0, copyBWidth * scale, copyBHeight * scale);
          rectangleSet = true;
          // }
          if (!firstTimeScroll) {
            if (document.getElementById('areaWrapper')) {
              document.getElementById('areaWrapper').scrollLeft = mapWidth + 2000;
              firstTimeScroll = true;
            }
          }
        },
      },
    },
  };

  const updateDataset = (labels, barData, lineData) => {
    if (mobilityChartInstance) {
      let selectedOption = option ? option : 'Confirmed';
      mobilityChartInstance.data.labels = labels;
      // mobilityChartInstance.data.datasets[0].data = lineData;
      mobilityChartInstance.data.datasets[0].data = barData;
      mobilityChartInstance.data.datasets[0].backgroundColor = optionConfig[selectedOption].color;
      mobilityChartInstance.data.datasets[0].borderColor = optionConfig[selectedOption].color;
      mobilityChartInstance.data.datasets[0].label = selectedOption + ' Cases';

      mobilityChartInstance.update();
    }
  };

  const checkDate = (recordDate, cutoffDate) => {
    let recordDateArr = recordDate.split('-');
    let cutoffDateArr = cutoffDate.split('-');

    var d1 = Number(recordDateArr[2] + recordDateArr[0] + recordDateArr[1]);
    var d2 = Number(cutoffDateArr[2] + cutoffDateArr[0] + cutoffDateArr[1]);
    return d1 > d2;
  };

  const getFormattedDateLabel = (date) => {
    var mS = ['Jan -', 'Feb -', 'Mar -', 'Apr -', 'May -', 'June -', 'July -', 'Aug -', 'Sept -', 'Oct -', 'Nov -', 'Dec -'];

    let dateArr = date.split('-');
    return mS[Number(dateArr[0] - 1)] + ' ' + dateArr[2].slice(-2);
  };

  const data = React.useMemo(() => {
    let labels = [];
    let barData = [];
    let lineData = [];
    if (chartData) {
      let new_data = chartData.stat.sort(function (a, b) {
        let aArr = a.day.split('-');
        let bArr = b.day.split('-');

        var d1 = Number(aArr[2] + aArr[0] + aArr[1]);
        var d2 = Number(bArr[2] + bArr[0] + bArr[1]);
        return d1 == d2 ? 0 : +(d1 > d2) || -1;
      });
      _.forEach(new_data, (value, index) => {
        let selectedOption = option ? option : 'Confirmed';
        labels.push(getFormattedDateLabel(value['day']));
        barData.push(value.stat[optionConfig[selectedOption].param]);
        let lineDataValue =
          value.stat['relativemobilityindex'] === -1 ? NaN : Math.round(value.stat['relativemobilityindex'] * 100);
        lineData.push(lineDataValue);
      });
    }
    return { labels, barData, lineData };
  }, [chartData]);

  useEffect(() => {
    if (chartData) {
      const { labels, barData, lineData } = data;
      updateDataset(labels, barData, lineData);
    }

    const handleOrientationChange = () => {
      if (window.matchMedia('(orientation: portrait)').matches) {
        setOrientation('portrait');
      }

      if (window.matchMedia('(orientation: landscape)').matches) {
        setOrientation('landscape');
      }
      if (mobilityChartInstance) {
        firstTimeScroll = false;
        mobilityChartInstance.update();
      }
    };

    // const handleOrientationChange = () => {
    //   if (mobilityChartInstance) {
    //     firstTimeScroll = false;
    //     mobilityChartInstance.update();
    //   }

    //   setOrientation(window.screen.orientation.type);
    // };
    window.addEventListener('orientationchange', handleOrientationChange);
    return () => window.removeEventListener('orientationchange', handleOrientationChange);
  }, [data, option]);

  const devWidth = window.innerWidth - 35;
  return (
    <>
      <div className='mt-10 flex chartSpacing'>
        <div className='chartWrapper' id='wrapper'>
          <div className='chartAreaWrapper' id='areaWrapper' style={{ width: devWidth }}>
            <div className='chartAreaWrapper2' id='wrapper2'>
              <canvas id='chart-Test' height='400' width='1500' ref={mobilityChartContainer}></canvas>
            </div>
          </div>
          {/*<canvas id='axis-B' height='400' width='0' style={{ backgroundColor: 'black' }}></canvas>*/}
          <canvas id='axis-A' height='400' width='0' style={{ backgroundColor: 'black' }}></canvas>
        </div>
      </div>
      <div className='justify-center' style={{ display: 'flex', flexFlow: 'row wrap' }}>
        {/*<Legend color={colors.darkgreen} label='Relative Mobility %' />}*/}
        <Legend
          color={optionConfig[option] ? optionConfig[option].color : 'red'}
          label={option}
          style={{ marginLeft: 20 }}
        />
      </div>
    </>
  );
};

export default MobilityChart;
