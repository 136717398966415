import React, { useEffect, useRef, useState, useContext } from "react";
import _ from "lodash";
import AppStateContext from "../contexts/AppState";
import MapTooltip from "./MapTooltip";
import CircleChart from "./CircleChart";
import colors from "../domain/colors";
import { mapValueInRange, getStatName } from "../util";
import { Marker } from "react-map-gl";
import statsConfig from "../domain/stats";

function RenderMarker({
  marker,
  hovered,
  filteredStatsConfig,
  isPerCapita,
  scaleFactor,
  setMapClickDisabled,
  drillDown,
  confirmedOnly,
}) {
  const dimension = Math.round(_.get(marker, "dimension"));
  const offset = Math.round((dimension / 2) * -1);
  const isClickable = !_.get(marker, "isSingle");
  const name = _.get(marker, "name");
  const stats = _.mapValues(_.keyBy(filteredStatsConfig, 'id'), stat => {
    return marker[stat.id]
  })

  return (
    <Marker
      latitude={_.get(marker, "latitude")}
      longitude={_.get(marker, "longitude")}
      className={`${marker.mapJoinValue === hovered ? "hover" : ""} ${
        !hovered ? "hoverable" : ""
      }`}
      key={_.get(marker, "key")}
    >
      <div
        onClick={(e) => {
          if (isClickable) {
            setMapClickDisabled(true);
            drillDown(_.get(marker, "name"));

            setTimeout(() => {
              setMapClickDisabled(false);
            }, 300);
          }
        }}
        className={`${
          isClickable ? "cursor-pointer marker-clickable" : ""
        } bg-transparent`}
      >
        <div
          className="relative z-10"
          style={{
            transform: `translateY(${(dimension / 2) * scaleFactor * -1}px)`,
          }}
        >
          <MapTooltip
            filteredStatsConfig={filteredStatsConfig}
            isPerCapita={isPerCapita}
            stats={stats}
            name={name}
          />
        </div>
        <div
          style={{
            transformOrigin: "top left",
            transform: `scale(${scaleFactor}) translate(${offset}px, ${offset}px)`,
          }}
        >
          <CircleChart
            data={confirmedOnly ?
              [
                {
                  originalValue: _.get(marker, "confirmedOriginal", 0),
                  value: _.get(marker, "confirmed"),
                  color: colors.yellow,
                  type: "confirmed,",
                }
              ] : [
              {
                originalValue: _.get(marker, "confirmedOriginal", 0),
                value: _.get(marker, "confirmed"),
                color: colors.yellow,
                type: "confirmed,",
              },
              {
                originalValue: _.get(marker, "activeOriginal", 0),
                color: colors.purple,
                value: _.get(marker, "active", 0),
                type: "active",
              },
              {
                originalValue: _.get(marker, "recoveredOriginal", 0),
                color: colors.blue,
                value: _.get(marker, "recovered", 0),
                type: "recovered",
              },
              {
                originalValue: _.get(marker, "deadOriginal", 0),
                color: colors.red,
                value: _.get(marker, "dead", 0),
                type: "dead",
              },
            ]}
            total={_.get(marker, "total")}
            strokeWidth={4}
            dimension={dimension}
          />
        </div>
      </div>
    </Marker>
  );
}

export default function ({ zoom, markerData, setMapClickDisabled, confirmedOnly }) {
  const appState = useContext(AppStateContext);
  const mapLoaded = _.get(appState, "mapLoaded");
  const scaleFactor = mapValueInRange(zoom, 0, 24, 0.3, 1);

  const currentMapHoveredFeature = _.get(appState, "currentMapHoveredFeature");
  // Coerce null to false to ensure that we're not matching null to null
  const hovered =
    (_.get(currentMapHoveredFeature, "type") === _.get(appState, "data.type")
      ? _.get(currentMapHoveredFeature, "identifier")
      : false) || false;

  const filteredStatsConfig = _.filter(statsConfig, (stat) =>
    _.get(appState, ["stats", stat.id])
  );

  const isPerCapita = _.get(appState, "toggleType") === "capita";
  return (
    <>
      {markerData &&
        mapLoaded &&
        markerData.length > 0 &&
        markerData
          .map((marker, idx) => {
          return (
            <RenderMarker
              marker={marker}
              hovered={hovered}
              filteredStatsConfig={filteredStatsConfig}
              isPerCapita={isPerCapita}
              appState={appState}
              scaleFactor={scaleFactor}
              key={_.get(marker, "key")}
              setMapClickDisabled={setMapClickDisabled}
              drillDown={appState.actions.drillDown}
              confirmedOnly={confirmedOnly}
            />
          );
        })}
    </>
  );
}
