import React from "react";
import colors from "/domain/colors";

export const ChevronDown = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const ChevronRight = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M8 16.59L12.58 12 8 7.41 9.41 6l6 6-6 6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
export const ChevronLeft = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const ChevronUp = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const MapMarked = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" {...props}>
      <path
        d="M288 0c-69.59 0-126 56.41-126 126 0 56.26 82.35 158.8 113.9 196.02 6.39 7.54 17.82 7.54 24.2 0C331.65 284.8 414 182.26 414 126 414 56.41 357.59 0 288 0zm0 168c-23.2 0-42-18.8-42-42s18.8-42 42-42 42 18.8 42 42-18.8 42-42 42zM20.12 215.95A32.006 32.006 0 0 0 0 245.66v250.32c0 11.32 11.43 19.06 21.94 14.86L160 448V214.92c-8.84-15.98-16.07-31.54-21.25-46.42L20.12 215.95zM288 359.67c-14.07 0-27.38-6.18-36.51-16.96-19.66-23.2-40.57-49.62-59.49-76.72v182l192 64V266c-18.92 27.09-39.82 53.52-59.49 76.72-9.13 10.77-22.44 16.95-36.51 16.95zm266.06-198.51L416 224v288l139.88-55.95A31.996 31.996 0 0 0 576 426.34V176.02c0-11.32-11.43-19.06-21.94-14.86z"
        fill="currentColor"
      />
    </svg>
  );
};

export const LineChart = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path
        d="M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Globe = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M16 27.625c6.422 0 11.625-5.203 11.625-11.625S22.422 4.375 16 4.375 4.375 9.578 4.375 16 9.578 27.625 16 27.625zm2.002-2.47l.815-2.199c.094-.258.155-.525.225-.792.052-.187.15-.36.29-.502l.53-.53c.413-.407.643-.965.643-1.546 0-.38-.15-.745-.418-1.013l-.642-.642a1.497 1.497 0 00-1.06-.44H15.25c-.44-.22-1.008-1.5-1.5-1.5s-.98-.118-1.42-.338l-.52-.258a.559.559 0 01.075-1.031l1.462-.487a.743.743 0 01.726.145l.436.38a.37.37 0 00.244.093h.263c.28 0 .459-.295.337-.543l-.731-1.463a.371.371 0 01.075-.436l.464-.45a.369.369 0 01.262-.108h.422a.38.38 0 00.267-.107l.375-.375a.376.376 0 000-.53l-.22-.22a.376.376 0 010-.53l.703-.703a.753.753 0 000-1.06l-1.326-1.326c.117-.005.234-.019.356-.019 3.666 0 6.834 2.119 8.377 5.19l-.61.304c-.173.089-.323.22-.431.38l-.919 1.378c-.253.38-.253.871 0 1.246l.844 1.266c.155.234.394.399.66.469l1.37.342A9.392 9.392 0 0118 25.155zm-4.59-17.199l-1.125-.562c.699-.3 1.44-.511 2.213-.638v.53a.751.751 0 01-1.088.67zM16 25.375c-5.17 0-9.375-4.205-9.375-9.375 0-1.364.3-2.658.825-3.83a405.7 405.7 0 001.622 2.396c.244.356.525.684.848.97l.038.033c.445.403.947.75 1.481 1.022.656.328 1.613.853 2.288 1.223.478.263.773.764.773 1.313v1.5c0 .398.16.778.44 1.059.704.708 1.14 1.814 1.06 2.405v1.284z"
      />
    </svg>
  );
};

export const BarChart = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M27.25 25a.75.75 0 00.75-.75v-.75a.75.75 0 00-.75-.75h-21v-15A.75.75 0 005.5 7h-.75a.75.75 0 00-.75.75V23.5A1.5 1.5 0 005.5 25h21.75zm-3.6-4.5c.3 0 .6-.3.6-.6V9.1c0-.3-.3-.6-.6-.6H22.6c-.3 0-.6.3-.6.6v10.8c0 .3.3.6.6.6h1.05zm-9 0c.3 0 .6-.3.6-.6v-9.3c0-.3-.3-.6-.6-.6H13.6c-.3 0-.6.3-.6.6v9.3c0 .3.3.6.6.6h1.05zm4.5 0c.3 0 .6-.3.6-.6v-6.3c0-.3-.3-.6-.6-.6H18.1c-.3 0-.6.3-.6.6v6.3c0 .3.3.6.6.6h1.05zm-9 0c.3 0 .6-.3.6-.6v-3.3c0-.3-.3-.6-.6-.6H9.1c-.3 0-.6.3-.6.6v3.3c0 .3.3.6.6.6h1.05z"
      />
    </svg>
  );
};

export const List = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <path
        fill="currentcolor"
        fillRule="nonzero"
        d="M8.25 10.75A.75.75 0 009 10V7a.75.75 0 00-.75-.75h-3A.75.75 0 004.5 7v3c0 .414.336.75.75.75h3zm19.5-1.125a.75.75 0 00.75-.75v-.75a.75.75 0 00-.75-.75h-15a.75.75 0 00-.75.75v.75c0 .414.336.75.75.75h15zM8.25 18.25A.75.75 0 009 17.5v-3a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h3zm19.5-1.125a.75.75 0 00.75-.75v-.75a.75.75 0 00-.75-.75h-15a.75.75 0 00-.75.75v.75c0 .414.336.75.75.75h15zM8.25 25.75A.75.75 0 009 25v-3a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h3zm19.5-1.125a.75.75 0 00.75-.75v-.75a.75.75 0 00-.75-.75h-15a.75.75 0 00-.75.75v.75c0 .414.336.75.75.75h15z"
      />
    </svg>
  );
};

export const Sliders = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M16.75 11.5c.412 0 .75-.337.75-.75V9.625h9.75c.412 0 .75-.337.75-.75v-.75a.752.752 0 00-.75-.75H17.5V6.25a.752.752 0 00-.75-.75h-1.5a.752.752 0 00-.75.75v1.125H4.75a.752.752 0 00-.75.75v.75c0 .413.338.75.75.75h9.75v1.125c0 .413.337.75.75.75h1.5zm6 7.5c.412 0 .75-.337.75-.75v-1.125h3.75c.412 0 .75-.337.75-.75v-.75a.752.752 0 00-.75-.75H23.5V13.75a.752.752 0 00-.75-.75h-1.5a.752.752 0 00-.75.75v1.125H4.75a.752.752 0 00-.75.75v.75c0 .413.338.75.75.75H20.5v1.125c0 .413.337.75.75.75h1.5zm-12 7.5c.412 0 .75-.338.75-.75v-1.125h15.75c.412 0 .75-.338.75-.75v-.75a.752.752 0 00-.75-.75H11.5V21.25a.752.752 0 00-.75-.75h-1.5a.752.752 0 00-.75.75v1.125H4.75a.752.752 0 00-.75.75v.75c0 .412.338.75.75.75H8.5v1.125c0 .412.337.75.75.75h1.5z"
      />
    </svg>
  );
};

export const Play = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2400/svg"
      viewBox="0 0 24 24"
      className={"h-4 w-5 h-5 block"}
    >
      <path
        fill={props.isactive ? '#FFF' : colors.gray}
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z"
      />
      <path
        fill={props.isactive ? colors.gray : "#FFF"}
        fillRule="nonzero" d="M10 16.5v-9l6 4.5z"
      />
    </svg>
  )
}
export const CirclePlay = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z" />
      <path fill="#FFF" fillRule="nonzero" d="M10 16.5v-9l6 4.5z" />
    </svg>
  );
};
