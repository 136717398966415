import React from "react";
import { Source, Layer } from "react-map-gl";
import colors from "/domain/colors"

export default function HighlightAdmin4({
  admin4LayerId,
  admin4SourceId,
  admin4SourceLayer,
  hoverIdentifier,
  beforeId,
}) {

  const hoverIdentifier1 = hoverIdentifier || null;
  return (
    <Source
      id={admin4SourceId}
      type="vector"
      url="mapbox://mapbox.boundaries-adm1-v3"
    >
      <Layer
        id={admin4LayerId}
        beforeId={beforeId}
        source-layer={admin4SourceLayer}
        minzoom={1.8}
        maxzoom={6}
        type="fill"
        paint={{
          "fill-opacity": 0.5,
          "fill-outline-color": [
            "case",
            [
              "boolean",
              [
                "match",
                ["get", "iso_3166_1"],
                ["CA", "CN", "AU", "US"],
                true,
                false,
              ],
            ],
            "white",
            "transparent",
          ],
          "fill-color": [
            "case",
              ["all",
                ["==", ["id"], hoverIdentifier1],
                [
                  "boolean",
                  [
                    "match",
                    ["get", "iso_3166_1"],
                    ["CA", "CN", "AU", "US"],
                    true,
                    false,
                  ],
                ]
              ],
            colors.darkgreen,
            colors['map-background']
          ],
        }}
      />
    </Source>
  );
}
