import React, {
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import Map from "../../components/Map";
import {default as USCountyMap} from "../../components/maps/us-cases-by-county/Map";
import Chart from "../../components/Chart";
import RegionTitle from "../../components/RegionTitle";
import { Desktop as TopBar } from "../../components/TopBar";
import Stats from "../../components/Stats";
import { ChevronLeft } from "../../components/icons";
import _ from "lodash";
import AppStateContext from "../../contexts/AppState";
import { clamp } from "../../util";

function useDimensions(cache) {
  const ref = useRef();
  const [dimensions, setDimensions] = useState({});
  useEffect(() => {
    ref.current && setDimensions(JSON.stringify(ref.current.getBoundingClientRect()));
  }, [ref.current].concat(cache || []));

  return [ref, dimensions];
}

export default function MapPage() {
  const appState = useContext(AppStateContext);
  const initDone = _.get(appState, "initDone");
  const page = _.get(appState, "page");
  const mapRightPanelHidden = _.get(appState, "mapRightPanelHidden");
  const data = _.get(appState, "data");
  const [renderStats, setRenderStats] = useState(false);
  const [sidebarRef, { width }] = useDimensions([data, renderStats]);

  const ready = !_.isEmpty(data) && _.get(appState, "mapLoaded");

  useLayoutEffect(() => {
    if (!mapRightPanelHidden) {
      setRenderStats(true);
    }
  }, [mapRightPanelHidden]);

  useLayoutEffect(() => {
    appState.actions.setSidebarWidth(mapRightPanelHidden ? 0 : width);
  }, [width, mapRightPanelHidden, data]);

  return (
    <>
      <div className="fixed inset-0 transition-all transform duration-100 ease-in">
        {page === "usCountyAnimation" ? <USCountyMap/> : <Map/>}
      </div>
      <div
        className="shadow-right"
        style={{
          marginRight: `${clamp(-1200, 0, -800 - (800 - (width + 50)))}px`,
        }}
      ></div>
      <div
        className={`relative flex self-center items-center justify-center mt-4 transition-all duration-300 ease transform ${
          ready ? "opacity-100 scale-100" : "opacity-0 scale-90"
        }`}
      >
        <TopBar />
      </div>
      {page !== "usCountyAnimation" && (
        <>
          <RegionTitle className="mt-4 mb-4 relative mr-6 pr-1 items-center flex self-end" />
          <div
            className="relative self-end mt-4 mb-8 flex-grow right-panel flex min-h-0"
            ref={sidebarRef}
            onTransitionEnd={(e) => {
              if (mapRightPanelHidden) {
                setRenderStats(false);
              }
            }}
          >
            <div
              className="absolute bg-sharecare-darkgreen-semi right-panel-toggle cursor-pointer"
              onClick={() => {
                appState.actions.toggleMapRightPanelHidden();
              }}
            >
              <div className="absolute handle bg-white rounded-full text-sharecare-darkgreen">
                <ChevronLeft />
              </div>
            </div>
            {renderStats && (
              <div className="right-panel-inner flex flex-col min-h-0">
                <Stats />
                <div className="chart-wrap mr-6 self-end -mb-6 mt-6 pt-6 pb-3">
                  <Chart />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
