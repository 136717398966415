import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import AppStateContext from '../contexts/AppState';
import _, { map } from 'lodash';
import Chartjs from 'chart.js';
import colors from '../domain/colors';
import { numberWithCommas, abbreviateNumber } from '../util';
import { chartDataset, optionConfig } from '../chartUtil';
import { useQuery } from 'react-query';
import { getRegionTimeseries } from '../domain/api';
import Legend from './Legend';

const MAP_WIDTH_DESKTOP = 1500;
const MAP_WIDTH_MOBILE = 1500;

const GlobalChart = ({
  topRegionTimeseries,
  option,
  isMobile,
  choice,
  highlight,
  highlightPath,
  graphType,
  chartView,
  parentUsstate,
  setShowLegend,
  showLegend,
  fetchDataType,
}) => {
  const appState = useContext(AppStateContext);

  const regionData = useQuery(
    [
      'regionData',
      {
        path: highlight !== 'All' ? (chartView === 'country' ? highlightPath.slice(0, 2) : highlightPath) : [],
        dataType: fetchDataType,
        option: option,
        chartView: chartView,
        choice: choice,
      },
    ],
    getRegionTimeseries
  );

  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [orientation, setOrientation] = useState('portrait');
  let firstTimeScroll = false;
  let rectangleSet = false;

  useEffect(() => {
    if (!firstTimeScroll) {
      if (document.getElementById('global-areaWrapper')) {
        document.getElementById('global-areaWrapper').scrollLeft = MAP_WIDTH_DESKTOP;
        firstTimeScroll = true;
      }
    }
  });

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  const chartConfig = {
    type: 'line',
    spanGaps: false,
    data: {
      labels: ['No Data'],
      datasets: chartDataset,
    },
    options: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          fontColor: 'white',
        },
      },
      tooltips: {
        // enabled: isMobile ? false : true,
        displayColors: false,
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            return label ? `${label}:${numberWithCommas(tooltipItem.yLabel)}` : '';
          },
        },
      },
      scales: {
        yAxes: [
          {
            id: 'A',
            type: graphType.value,
            position: 'left',
            ticks: {
              beginAtZero: true,
              fontColor: 'white',
              autoSkip: true,
              maxTicksLimit: 10,
              callback: function (label, index, labels) {
                return `${abbreviateNumber(label, true)} `;
              },
            },
            gridLines: {
              display: true,
              color: '#2d2d2d',
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              fontColor: 'white',
              autoSkip: true,
              maxTicksLimit: 20,
            },
            gridLines: {
              display: false,
              // zeroLineColor: 'white',
              color: 'white',
            },
          },
        ],
      },
      maintainAspectRatio: false,
      responsive: true,
      animation: {
        duration: 0,
        onProgress: function (animation) {},
        onComplete: function (animation) {
          const mapWidth = isMobile ? MAP_WIDTH_MOBILE : MAP_WIDTH_DESKTOP;
          const scale = window.devicePixelRatio;

          var sourceCanvas = animation.chart.canvas;
          var copyAWidth = animation.chart.scales['A'].width - 10;
          var copyAHeight = animation.chart.scales['A'].height + animation.chart.scales['A'].top + 10;

          if (document.getElementById('global-axis-A')) {
            var targetCtx = document.getElementById('global-axis-A').getContext('2d');
            targetCtx.scale(scale, scale);
            targetCtx.canvas.width = copyAWidth * scale;
            targetCtx.canvas.height = copyAHeight * scale;

            targetCtx.canvas.style.width = `${copyAWidth}px`;
            targetCtx.canvas.style.height = `${copyAHeight}px`;
            targetCtx.drawImage(
              sourceCanvas,
              0,
              0,
              copyAWidth * scale,
              copyAHeight * scale,
              0,
              0,
              copyAWidth * scale,
              copyAHeight * scale
            );
          }
          const devWidth = window.innerWidth - 35;
          rectangleSet = true;
          if (!firstTimeScroll) {
            document.getElementById('global-areaWrapper').scrollLeft = mapWidth;
            firstTimeScroll = true;
          }
        },
      },
    },
  };

  const reinitializeDataset = (lineData) => {
    chartInstance.options.scales.yAxes[0].type = graphType.value;
    let dataset = chartInstance.data.datasets;
    for (let i = 0; i < chartInstance.data.datasets.length; i++) {
      dataset[i].data = Array(lineData[0].data.length).fill(0);
      dataset[i].label = '';
      dataset[i].showLine = false;
      dataset[i].borderWidth = 5;
      dataset[i].pointRadius = 0;
      dataset[i].pointHoverRadius = 0;
    }
  };

  const setSameLevelData = (value, lineData) => {
    if (value.data.length < lineData[0].data.length) {
      let end = lineData[0].data.length - value.data.length;
      for (let i = 0; i < end; i++) value.data.unshift(0);
    }
  };

  const updateDataset = (labels, lineData, variableData) => {
    if (chartInstance && lineData.length > 0) {
      chartInstance.data.labels = labels;
      let count = 1;

      // Initialize dataset
      reinitializeDataset(lineData);
      let dataset = chartInstance.data.datasets;

      //new dataset
      _.forEach(lineData, (value) => {
        setSameLevelData(value, lineData);
        if (value && count < 26) {
          dataset[count].data = value.data;
          dataset[count].label = value.country;
          dataset[count].borderWidth = 1;
          if (count >= choice.value + 1 && count < 26) {
            dataset[count].showLine = false;
            dataset[count].pointRadius = 0;
            dataset[count].pointHoverRadius = 0;
          } else {
            dataset[count].showLine = true;
            dataset[count].pointRadius = 1.5;
            dataset[count].pointHoverRadius = 2;
          }
          count++;
        }
      });

      // Highlight data
      let selectRegionIndex = lineData.findIndex((data) => data.country === highlight);
      if (variableData.country) {
        setSameLevelData(variableData, lineData);
        dataset[0].data = variableData.data;
        dataset[0].label = variableData.country;
        dataset[0].showLine = true;
        dataset[0].borderWidth = 5;
        dataset[0].pointRadius = 2;
        dataset[0].pointHoverRadius = 3;
        dataset[0].borderColor = colors.red;
      }
      if (selectRegionIndex !== -1) {
        dataset[0].borderColor = dataset[selectRegionIndex + 1].borderColor;
        dataset[0].label = '';
        if (choice.value === 10 && selectRegionIndex > 9) {
          dataset[0].data = variableData.data;
          dataset[0].label = variableData.country;
        }
      }
      chartInstance.update();
    }
  };

  const checkDate = (recordDate, cutoffDate) => {
    let recordDateArr = recordDate.split('-');
    let cutoffDateArr = cutoffDate.split('-');

    var d1 = Number(recordDateArr[2] + recordDateArr[0] + recordDateArr[1]);
    var d2 = Number(cutoffDateArr[2] + cutoffDateArr[0] + cutoffDateArr[1]);
    return d1 > d2;
  };

  const getFormattedDateLabel = (date) => {
    var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    let dateArr = date.split('-');
    return mS[Number(dateArr[0] - 1)] + ' ' + dateArr[1];
  };

  const data = useMemo(() => {
    let labels = [];
    let lineData = [];
    let variableData = {};
    let flag = false;
    let key = '';
    if (chartView === 'Global') key = 0;
    if (chartView === 'country') key = 1;
    if (chartView === 'state' || chartView === 'county') key = 2;
    if (topRegionTimeseries.data) {
      _.forEach(topRegionTimeseries.data, (value) => {
        let temp_data = { data: [], country: value['region'].split(':')[key] };
        let new_data = value.stat.sort(function (a, b) {
          let aArr = a.day.split('-');
          let bArr = b.day.split('-');

          var d1 = Number(aArr[2] + aArr[0] + aArr[1]);
          var d2 = Number(bArr[2] + bArr[0] + bArr[1]);
          return d1 == d2 ? 0 : +(d1 > d2) || -1;
        });
        _.forEach(new_data, (value1, index) => {
          let selectedOption = option ? option : 'Confirmed';
          if (!flag) {
            labels.push(getFormattedDateLabel(value1['day']));
          }
          temp_data['data'].push(value1.stat[optionConfig[selectedOption].param]);
        });
        flag = true;
        lineData.push(temp_data);
      });
    }

    // variable data based on country selection
    if (regionData.data && typeof regionData.data['region'] === 'string') {
      variableData = { data: [], country: regionData.data['region'].split(':')[key], dates: [] };
      let new_data = regionData.data.stat.sort(function (a, b) {
        let aArr = a.day.split('-');
        let bArr = b.day.split('-');

        var d1 = Number(aArr[2] + aArr[0] + aArr[1]);
        var d2 = Number(bArr[2] + bArr[0] + bArr[1]);
        return d1 == d2 ? 0 : +(d1 > d2) || -1;
      });
      _.forEach(new_data, (value, index) => {
        let selectedOption = option ? option : 'Confirmed';
        variableData['data'].push(value.stat[optionConfig[selectedOption].param]);
      });
    }

    return { labels, lineData, variableData };
  }, [topRegionTimeseries.data, regionData.data]);

  useEffect(() => {
    if (topRegionTimeseries.data) {
      const { labels, lineData, variableData } = data;
      updateDataset(labels, lineData, variableData);
    }

    const handleOrientationChange = () => {
      if (window.matchMedia('(orientation: portrait)').matches) {
        setOrientation('portrait');
      }

      if (window.matchMedia('(orientation: landscape)').matches) {
        setOrientation('landscape');
      }
      if (chartInstance) {
        firstTimeScroll = false;
        chartInstance.update();
      }
    };

    window.addEventListener('orientationchange', handleOrientationChange);
    return () => window.removeEventListener('orientationchange', handleOrientationChange);
  }, [data, option, choice, highlight, graphType, chartView, parentUsstate]);

  const mouseOver = (event) => {
    if (event.target.id) {
      let elem = document.getElementById(event.target.id);
      if (elem) elem.style.backgroundColor = '#33bc9d';
      let index = Number(event.target.id.split('-')[1]);
      if (index > 0) {
        let arr = chartInstance.data.datasets[0];
        chartInstance.data.datasets[0] = chartInstance.data.datasets[index];
        chartInstance.data.datasets[index] = arr;
        chartInstance.data.datasets[0].borderWidth = 5;
        chartInstance.data.datasets[index].borderWidth = 1;
        // if (chartInstance.data.datasets[0].showLine) chartInstance.data.datasets[0].borderWidth = 1;
        chartInstance.update();
      }
    }
  };

  const mouseOut = (event) => {
    if (event.target.id) {
      let elem = document.getElementById(event.target.id);
      if (elem) elem.style.backgroundColor = '#000';
      let index = Number(event.target.id.split('-')[1]);
      if (index > 0) {
        let arr = chartInstance.data.datasets[index];
        chartInstance.data.datasets[index] = chartInstance.data.datasets[0];
        chartInstance.data.datasets[0] = arr;
        chartInstance.data.datasets[index].borderWidth = 1;
        chartInstance.data.datasets[0].borderWidth = 5;
        // if (chartInstance.data.datasets[0].showLine) chartInstance.data.datasets[0].borderWidth = 5;
        chartInstance.update();
      }
    }
  };
  // let showLegends = () => {
  //   setShowLegend(!showLegend);
  // };
  const devWidth = window.innerWidth - 35;

  return (
    <>
      <div style={{ padding: '0 20px', position: 'relative' }}>
        <div style={{ position: 'absolute', top: chartView === 'Global' ? 0 : 40, right: 10 }}>
          {showLegend && (
            <div className='chart-legend-div hide-native-scrollbar'>
              {chartInstance && (
                <div>
                  {map(Array(choice.value + 1).fill(0), (dataset, key) => (
                    <div key={key}>
                      {key > 0 && chartInstance.data.datasets[key].label && (
                        <Legend
                          chartName='Global'
                          current={key}
                          color={chartInstance.data.datasets[key].borderColor}
                          label={chartInstance.data.datasets[key].label}
                          mouseOver={mouseOver}
                          mouseOut={mouseOut}
                        />
                      )}
                    </div>
                  ))}
                  {chartInstance.data.datasets[0].label && (
                    <Legend
                      chartName='Global'
                      key={0}
                      color={chartInstance.data.datasets[0].borderColor}
                      label={chartInstance.data.datasets[0].label}
                      mouseOver={mouseOver}
                      mouseOut={mouseOut}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className='mt-10 flex chartSpacing'>
        <div className='chartWrapper' id='wrapper'>
          <div className='chartAreaWrapper' id='global-areaWrapper' style={{ width: devWidth }}>
            <div className='chartAreaWrapperglobal' id='global-wrapper2'>
              <canvas id='global-chart-Test' height='400' width='1500' ref={chartContainer}></canvas>
            </div>
          </div>
          <canvas id='global-axis-A' height='400' width='0' style={{ backgroundColor: 'black' }}></canvas>
        </div>
      </div>
    </>
  );
};

export default GlobalChart;
