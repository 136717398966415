export default {
  mainland: {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-123.123779, 48.227039],
          [-125.067985, 48.370848],
          [-124.189317, 34.669359],
          [-114.724731, 32.711044],
          [-114.930725, 32.521342],
          [-111.08551, 31.325487],
          [-108.193359, 31.325487],
          [-108.187866, 31.760867],
          [-106.44104, 31.737511],
          [-104.699707, 29.649869],
          [-103.117676, 28.88316],
          [-102.612305, 29.716681],
          [-101.480713, 29.678508],
          [-99.283447, 26.382028],
          [-97.058716, 25.730633],
          [-80.628662, 24.417142],
          [-66.780396, 44.785734],
          [-67.719727, 45.813486],
          [-67.758179, 47.271775],
          [-69.213867, 47.480088],
          [-71.586914, 45.1123],
          [-74.849854, 45.058001],
          [-77.305298, 43.761176],
          [-79.299316, 43.590338],
          [-79.013672, 42.867912],
          [-83.034668, 41.910453],
          [-81.331787, 45.344424],
          [-84.126389, 46.531937],
          [-88.36853, 48.314255],
          [-94.482422, 48.857487],
          [-94.795532, 49.357334],
          [-95.157394, 49.390418],
          [-95.157394, 49.000493],
          [-121.742592, 49.000267],
          [-123.318787, 49.000042],
          [-123.123779, 48.227039],
        ],
      ],
    },
  },
  alaska: {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-161.806382, 71.883578],
          [-172.4415, 63.66576],
          [-166.198961, 57.938183],
          [-168.924699, 50.007739],
          [-147.480963, 59.265881],
          [-140.188695, 58.950008],
          [-131.748164, 54.316523],
          [-129.550781, 55.553495],
          [-135.131836, 60.326948],
          [-135.131836, 60.326948],
          [-135.131836, 60.326948],
          [-135.131836, 60.326948],
          [-140.976562, 60.326948],
          [-141.0645, 69.9303],
          [-161.806382, 71.883578],
        ],
      ],
    },
  },
  hawaii: {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-157.390081, 20.529933],
          [-155.500433, 18.646245],
          [-155.500433, 18.646245],
          [-155.500433, 18.646245],
          [-155.500433, 18.646245],
          [-155.500433, 18.646245],
          [-155.500433, 18.646245],
          [-155.500433, 18.646245],
          [-154.379934, 19.808054],
          [-158.818495, 22.553147],
          [-160.422346, 21.983801],
          [-157.390081, 20.529933],
        ],
      ],
    },
  },
};
