import React from "react";
import { Source, Layer } from "react-map-gl";
import colors from "/domain/colors";

export default function HighlightCountry({
  countryLayerId,
  countrySourceId,
  countrySourceLayer,
  hoverIdentifier,
  beforeId,
}) {

  const hoverIdentifier1 = hoverIdentifier || null;
  return (
    <Source
      id={countrySourceId}
      type="vector"
      url="mapbox://mapbox.boundaries-adm0-v3"
    >
      <Layer
        id={countryLayerId}
        beforeId={beforeId}
        source-layer={countrySourceLayer}
        type="fill"
        paint={{
          "fill-opacity": 0.5,
          "fill-color": [
            "case",
            ["==", ["get", "iso_3166_1"], hoverIdentifier1],
            colors.darkgreen,
            colors['map-background']
          ],
        }}
      />
    </Source>
  );
}
