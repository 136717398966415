import React, { useContext, useEffect, useState, useCallback } from "react";
import AppStateContext from "../contexts/AppState";
import { getRegionTimeseries } from "../domain/api";
import { useQuery } from "react-query";
import colors from "../domain/colors";
import _ from "lodash";
import { ResponsiveLine } from "@nivo/line";
import { numberWithCommas } from "../util";

function inc(n) {
  return n + 1;
}

function dec(n) {
  return n - 1;
}

const LABELS = {
  dead: "Dead",
  confirmed: "Confirmed",
  // active: "Active",
  // recovered: "Recovered",
};

const COLORS = {
  dead: colors.red,
  // recovered: colors.blue,
  // active: colors.purple,
  confirmed: colors.yellow,
};

function calculateMagnitude(n) {
  var order = Math.floor(Math.log(n) / Math.LN10 + 0.000000001);
  return Math.pow(10, order);
}
function calculateYAxisFactor(value) {
  const magnitude = calculateMagnitude(value);
  return Math.ceil(value / magnitude) * magnitude;
}

function calculateYAxisValues(chartType, data) {
  const points = _.map(_.flatten(_.map(data, (d) => _.get(d, "data"))), (v) =>
    _.get(v, "y")
  );

  if (!points.length) return 5;

  const maxValue = Math.max.apply(null, points);
  const minValue = Math.min.apply(null, points);

  if (chartType === "linear") {
    const factor = calculateYAxisFactor(maxValue / 4);
    const values = [];
    for (let i = 0; i <= 4; i++) {
      values.push(i * factor);
    }
    return values;
  }

  const log = Math.ceil(Math.log10(maxValue));
  const minLog = Math.floor(Math.log10(minValue));
  const values = [];
  for (let i = minLog; i <= log; i++) {
    values.push(Math.pow(10, i));
  }
  return values;
}

export default function () {
  const appState = useContext(AppStateContext);
  const [chartType, setChartType] = useState("linear");

  const derivedChartType = appState.dataType === "new" ? "linear" : chartType;

  const regionTimeseries = useQuery(
    ["regionTimeseries", { path: appState.path, dataType: appState.dataType }],
    getRegionTimeseries
  );

  const [chartData, setChartData] = useState(regionTimeseries.data);

  useEffect(() => {
    if (regionTimeseries.data) {
      setChartData(regionTimeseries.data);
    }
  }, [regionTimeseries.data]);

  const data = React.useMemo(() => {
    if (!chartData) return [];
    const series = _.reduce(
      [...chartData.stat].reverse(),
      (acc, d, idx) => {
        acc.dead.push({ x: _.get(d, "day"), y: _.get(d, "stat.dead", 0) });
        acc.recovered.push({
          x: _.get(d, "day"),
          y: _.get(d, "stat.recovered", 0),
        });
        acc.active.push({ x: _.get(d, "day"), y: _.get(d, "stat.active", 0) });
        acc.confirmed.push({
          x: _.get(d, "day"),
          y: _.get(d, "stat.confirmed", 0),
        });

        return acc;
      },
      { dead: [], confirmed: [], recovered: [], active: [] }
    );

    const filtered = _.filter(
      [
        {
          id: "dead",
          color: colors.red,
          data: series.dead,
        },
        {
          id: "recovered",
          color: colors.blue,
          data: series.recovered,
        },
        {
          id: "active",
          color: colors.purple,
          data: series.active,
        },
        {
          id: "confirmed",
          color: colors.yellow,
          data: series.confirmed,
        },
      ],
      (d) => {
        return _.get(appState, ["stats", d.id]);
      }
    );

    return _.map(filtered, (d) => {
      d.data = _.map(d.data, (datum, idx) => {
        // We need to increase 0 to 1 one to be able to render log graph
        // since log graphs can be rendered only in positive or negative
        // domain. We are decreasing numbers later in the tooltip formatter
        // to show the correct data.
        //
        // TODO: Check if it messes up any graphs with small values and figure
        // out a solution
        return { ...datum, y: inc(Math.max(0, datum.y)) };
      });
      return d;
    });
  }, [chartData, appState.stats]);

  const yAxisValues = calculateYAxisValues(derivedChartType, data);

  if (!_.get(chartData, "stat.length")) return null;
  return (
    <div style={{ height: "90%" }} className="text-white chart-wrap">
      <ResponsiveLine
        data={data}
        margin={{ top: 15, bottom: 50, left: 60 }}
        xScale={{ type: "time", format: "%m-%d-%Y", precision: "day" }}
        xFormat="time:%m-%d-%Y"
        yScale={{
          type: derivedChartType,
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: "%b %d",
          tickValues: "every monday",
          orient: "bottom",
          tickSize: 0,
          tickPadding: 5,
          tickRotation: -45,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickValues: yAxisValues,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: "middle",
          format: (v) => (v === 1 ? 0 : numberWithCommas(v)),
        }}
        gridYValues={yAxisValues}
        enableGridX={false}
        enablePoints={false}
        colors={(series) => {
          return series.color;
        }}
        tooltip={(slice) => {
          return (
            <div
              style={{
                color: COLORS[_.get(slice, "point.serieId")],
                background: "rgba(0,0,0,0.8)",
              }}
              className="uppercase text-sm font-semibold -ml-40 rounded p-2"
            >
              {_.get(slice, "point.data.xFormatted")}{" "}
              {LABELS[_.get(slice, "point.serieId")]}:{" "}
              {dec(_.get(slice, "point.data.y"))}
            </div>
          );
        }}
        theme={{
          axis: { ticks: { text: { fill: "rgba(255,255,255,0.4)" } } },
          grid: { line: { stroke: "rgba(255,255,255,0.2)" } },
        }}
        useMesh={true}
      />
      {appState.dataType === "cumulative" ? (
        <div className="flex justify-end">
          <label className="flex items-center cursor-pointer uppercase font-semibold text-sm leading-none text-white">
            <div className="relative toggle-button mr-2">
              <input
                className="hidden"
                type="checkbox"
                checked={chartType === "log"}
                onChange={(e) => {
                  setChartType(e.target.checked ? "log" : "linear");
                }}
              />
              <div className="w-5 h-5 border-sharecare-darkgreen border-solid border-2 rounded-full dot absolute"></div>
              <div className="w-10 h-5 border-sharecare-darkgreen border-solid border-2 rounded-full"></div>
            </div>
            Logarithmic
          </label>
        </div>
      ) : null}
    </div>
  );
}
