import React, { useMemo, useContext } from "react";
import _ from "lodash";
import produce from "immer";
import AppStateContext from "../contexts/AppState";
import statsConfig from "../domain/stats";
import { ChevronDown, ChevronUp } from "./icons";
import { getPercentage, numberWithCommas } from "../util";

function getBarWidth(max, num) {
  const percentage = getPercentage(max, num);
  if (percentage === 0) return 0;
  return Math.max(1, percentage);
}

export default function SingleStat() {
  const appState = useContext(AppStateContext);
  const data = _.get(appState, "data");
  const filteredStatsConfig = _.filter(statsConfig, stat =>
    _.get(appState, ["stats", stat.id])
  );
  const max = _.reduce(
    filteredStatsConfig,
    (acc, stat) => {
      return Math.max(acc, _.get(data, ["stat", stat.id]));
    },
    0
  );
  return (
    <div className="flex flex-col justify-end">
      {_.map(filteredStatsConfig, stat => {
        const value = _.get(data, ["stat", stat.id]);
        return (
          <div
            key={stat.id}
            className={`flex justify-end text-white leading-tight mb-2`}
          >
            <div className="font-semibold flex items-start justify-end text-right py-1 mr-2">
              {stat.label}
            </div>
            <div className="py-1 px-2 font-extrabold w-48 relative text-right">
              <div
                className={`rounded absolute bg-sharecare-${stat.color} top-0 bottom-0 right-0`}
                style={{ width: `${getBarWidth(max, value)}%` }}
              ></div>
              <div className="relative">{numberWithCommas(value)}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
