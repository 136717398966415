import colors from "./domain/colors";
import _ from "lodash";

const stateExclusionList = [
    'Diamond Princess',
    'Grand Princess',
    'Grand Princess Cruise Ship',
    'Guam',
    'Northern Mariana Islands',
    'Unassigned Location (From Diamond Princess)',
    'Virgin Islands',
    'Wuhan Evacuee',
];

const chartGlobalOptions = [
    // { label: 'Active', value: 'Active' },
    { label: 'Confirmed', value: 'Confirmed' },
    { label: 'Deaths', value: 'Deaths' },
    // { label: 'Recovered', value: 'Recovered' },
    { label: 'Daily Confirmed', value: 'Daily Confirmed' },
    { label: 'Daily Deaths', value: 'Daily Deaths' },
    // { label: 'Daily Recovered', value: 'Daily Recovered' },
];

const chartUSOptions = [
    // { label: 'Active', value: 'Active' },
    { label: 'Confirmed', value: 'Confirmed' },
    { label: 'Deaths', value: 'Deaths' },
    // { label: 'Recovered', value: 'Recovered' },
    { label: 'Daily Confirmed', value: 'Daily Confirmed' },
    { label: 'Daily Deaths', value: 'Daily Deaths' },
    // { label: 'Daily Recovered', value: 'Daily Recovered' },
    // { label: 'Hospitalised', value: 'Hospitalised' },
    { label: 'Total tests', value: 'Total tests' }
];

const chartOptions = [
    { label: 'Confirmed', value: 'Confirmed' },
    { label: 'Deaths', value: 'Deaths' },
    { label: 'Daily Confirmed', value: 'Daily Confirmed' },
    { label: 'Daily Deaths', value: 'Daily Deaths' }
]

const chartStateOptions = [
    { label: 'Confirmed', value: 'Confirmed' },
    { label: 'Deaths', value: 'Deaths' },
    { label: 'Daily Confirmed', value: 'Daily Confirmed' },
    { label: 'Daily Deaths', value: 'Daily Deaths' },
    // { label: 'Hospitalised', value: 'Hospitalised' },
    { label: 'Total tests', value: 'Total tests' }
];

const chartChoices = [
    { label: 'Top 10', value: 10 },
    { label: 'Top 25', value: 25 }
];

const graphOptions = [
    { label: 'logarithmic', value: 'logarithmic' },
    { label: 'linear', value: 'linear' },
];

const chartBar = [
    { id: "Global", label: "Global", color: "yellow" },
    { id: "country", label: "US", color: "purple" },
    { id: "state", label: "States", color: "red" },
];

const optionConfig = {
    // Active: {
    //     color: colors.purple,
    //     param: 'active',
    // },
    Confirmed: {
        color: colors.yellow,
        param: 'confirmed',
    },
    Deaths: {
        color: colors.red,
        param: 'dead',
    },
    // Recovered: {
    //     color: colors.blue,
    //     param: 'recovered',
    // },
    'Daily Deaths': {
        color: colors.red,
        param: 'dead',
    },
    'Daily Confirmed': {
        color: colors.yellow,
        param: 'confirmed',
    },
    // 'Daily Recovered': {
    //     color: colors.blue,
    //     param: 'recovered',
    // },
    // Hospitalised : {
    //     color: colors.maroon,
    //     param: 'hospitalized',
    // },
    'Total tests': {
        color: colors.lightpurple,
        param: 'tested',
    },
};

const chartDataset =  [
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[0],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[1],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[2],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[3],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[4],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[5],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[6],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[7],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[8],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[9],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[10],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[11],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[12],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[13],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[14],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[15],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[16],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[17],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[18],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[19],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[20],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[21],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[22],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[23],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[24],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[25],
        fill: false,
    },
    {
        type: 'line',
        label: 'Confirmed Cases',
        data: [1],
        yAxisID: 'A',
        borderColor: colors.lineColorPallete[26],
        fill: false,
    },
];

const filterCases = (data, cases, option, choice) => {
    let filter_data = _.filter(data, (o) => {
        return o.stat[optionConfig[option.value].param] >= cases;
    });
    return _.sortBy(_.map(_.drop(filter_data.reverse(), choice.value), 'name'));
};

const updateMapPath = (newPath, appState) => {
    let mapPath = appState.path;
    _.forEach(mapPath, (value) => {
        appState.actions.goBack();
    });
    _.forEach(newPath, (value, index) => {
        appState.actions.drillDown(value);
    });
};

module.exports = {
    stateExclusionList,
    chartUSOptions,
    chartOptions,
    chartStateOptions,
    chartGlobalOptions,
    chartChoices,
    graphOptions,
    chartBar,
    optionConfig,
    chartDataset,
    filterCases,
    updateMapPath
};