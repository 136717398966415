import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import _, { map } from 'lodash';
import Chartjs from 'chart.js';
import colors from '../domain/colors';
import { abbreviateNumber, numberWithCommas } from '../util';
import { optionConfig } from '../chartUtil';
import { useQuery } from 'react-query';
import { getCountiesTimeseries, getRegionTimeseries } from '../domain/api';
import Legend from './Legend';
import Select from "react-select";

const MAP_WIDTH_DESKTOP = 1500;
const MAP_WIDTH_MOBILE = 1500;

const CountyChart = ({
  option,
  isMobile,
  highlight,
  highlightPath,
  chartView,
  topCountiesData,
  parentUsstate,
  parentChoice,
  parentAxis,
  fetchDataType,
}) => {
  const [chartType, setChartType] = useState('hundred');

  // Get data for user selected County
  const regionData = useQuery(
    [
      'regionData',
      {
        path: highlight.value !== 'All' ? highlightPath : [],
        dataType: fetchDataType,
        option: option,
        chartView: chartView,
        choice: parentChoice,
      },
    ],
    getRegionTimeseries
  );
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [orientation, setOrientation] = useState('portrait');
  const [showLegend, setShowLegend] = useState(null);
  const [highlightData, setHighLightData] = useState(null);
  const [legends, setLegends] = useState([]);
  let firstTimeScroll = useRef(false);
  let rectangleSet = false;

  useEffect(() => {
    if (showLegend) firstTimeScroll.current = true;
    if (!showLegend) firstTimeScroll.current = false;
  }, [showLegend]);

  // Create Chart
  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  useEffect(() => {
    if(chartView)
      firstTimeScroll.current = false;
  }, [chartView]);

  const chartConfig = {
    type: 'line',
    spanGaps: false,
    data: {
      labels: ['No Data'],
      datasets: [
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[0],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[1],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[2],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[3],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[4],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[5],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[6],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[7],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[8],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[9],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[10],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[11],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[12],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[13],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[14],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[15],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[16],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[17],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[18],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[19],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[20],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[21],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[22],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[23],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[24],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[25],
          fill: false,
        },
        {
          type: 'line',
          label: 'Confirmed Cases',
          data: [1],
          yAxisID: 'A',
          borderColor: colors.lineColorPallete[25],
          fill: false,
        },
      ],
    },
    options: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          fontColor: 'white',
        },
      },
      tooltips: {
        // enabled: isMobile ? false : true,
        displayColors: false,
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
              label += ': ';
            }
            label += numberWithCommas(tooltipItem.yLabel);
            return label;
          },
          title: (tooltipItems, data) => {
            if (data.datasets[tooltipItems[0].datasetIndex].dates.length > 0)
              return 'Date: ' + data.datasets[tooltipItems[0].datasetIndex].dates[tooltipItems[0].index];
            else return tooltipItems[0].label;
          },
          beforeLabel: (tooltipItems, data) => {
            if (data.datasets[tooltipItems.datasetIndex].dates.length > 0) return `Days: ${tooltipItems.label}`;
            else return '';
          },
        },
      },
      scales: {
        yAxes: [
          {
            id: 'A',
            type: parentAxis.value,
            position: 'left',
            ticks: {
              beginAtZero: true,
              fontColor: 'white',
              autoSkip: true,
              maxTicksLimit: 10,
              callback: function (label, index, labels) {
                return `${abbreviateNumber(label, true)} `;
              },
            },
            gridLines: {
              display: true,
              color: '#2d2d2d',
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              fontColor: 'white',
              autoSkip: true,
              maxTicksLimit: 20,
            },
          },
        ],
      },
      maintainAspectRatio: false,
      responsive: true,
      animation: {
        duration: 0,
        onProgress: function (animation) {},
        onComplete: function (animation) {
          const mapWidth = isMobile ? MAP_WIDTH_MOBILE : MAP_WIDTH_DESKTOP;
          const scale = window.devicePixelRatio;

          var sourceCanvas = animation.chart.canvas;
          var copyAWidth = animation.chart.scales['A'].width - 10;
          var copyAHeight = animation.chart.scales['A'].height + animation.chart.scales['A'].top + 10;

          if (document.getElementById('county-axis-A')) {
            var targetCtx = document.getElementById('county-axis-A').getContext('2d');
            targetCtx.scale(scale, scale);
            targetCtx.canvas.width = copyAWidth * scale;
            targetCtx.canvas.height = copyAHeight * scale;

            targetCtx.canvas.style.width = `${copyAWidth}px`;
            targetCtx.canvas.style.height = `${copyAHeight}px`;
            targetCtx.drawImage(
              sourceCanvas,
              0,
              0,
              copyAWidth * scale,
              copyAHeight * scale,
              0,
              0,
              copyAWidth * scale,
              copyAHeight * scale
            );
          }
          const devWidth = window.innerWidth - 35;
          rectangleSet = true;
          if (!firstTimeScroll.current) {
            document.getElementById('county-areaWrapper').scrollLeft = mapWidth;
            firstTimeScroll.current = true;
          }
        },
      },
    },
  };

  const refresh = (loc) => {
    if (loc !== 'chart' && showLegend === false) {
      if (highlightData !== null && highlightData.hasOwnProperty('indexing')) {
        chartInstance.data.datasets[0] = chartInstance.data.datasets[highlightData.indexing];
        chartInstance.data.datasets[highlightData.indexing] = highlightData;
        chartInstance.data.datasets[highlightData.indexing].borderWidth = 1;
        chartInstance.update();
      }
    }
    if (loc === 'chart') {
      if (highlightData !== null && highlightData.hasOwnProperty('indexing')) {
        chartInstance.data.datasets[0] = chartInstance.data.datasets[highlightData.indexing];
        chartInstance.data.datasets[highlightData.indexing] = highlightData;
        chartInstance.data.datasets[highlightData.indexing].borderWidth = 1;
      }
    }
  };

  const reinitializeDataset = (lineData) => {
    chartInstance.options.scales.yAxes[0].type = parentAxis.value;
    let dataset = chartInstance.data.datasets;
    for (let i = 0; i < chartInstance.data.datasets.length; i++) {
      dataset[i].data = Array(lineData[0].data.length).fill(0);
      dataset[i].label = '';
      dataset[i].showLine = false;
      dataset[i].borderWidth = 5;
      dataset[i].pointRadius = 0;
      dataset[i].pointHoverRadius = 0;
      dataset[i].indexing = i;
    }
  };

  // Align all the datasets' length, keep 0 for not available dates
  const setSameLevelData = (value, lineData) => {
    if (value.data.length < lineData[0].data.length && chartType === 'regular') {
      let end = lineData[0].data.length - value.data.length;
      for (let i = 0; i < end; i++) value.data.unshift(0);
    }
  };

  // Update chart dataset for all the changes
  const updateDataset = (labels, lineData, variableData) => {
    if (chartInstance && lineData.length > 0) {
      let labelsLength = 0;
      let count = 1;
      if (chartType === 'regular') {
        chartInstance.data.labels = labels;
      }
      refresh('chart');
      // Initialize dataset
      reinitializeDataset(lineData);
      let dataset = chartInstance.data.datasets;

      //new dataset
      _.forEach(lineData, (value) => {
        setSameLevelData(value, lineData);
        if (value && count < 26) {
          dataset[count].data = value.data;
          dataset[count].label = value.country;
          dataset[count].dates = value.dates;
          dataset[count].borderWidth = 1;
          if (count >= parentChoice.value + 1 && count < 26) {
            dataset[count].showLine = false;
            dataset[count].pointRadius = 0;
            dataset[count].pointHoverRadius = 0;
          } else {
            dataset[count].showLine = true;
            dataset[count].pointRadius = 1.5;
            dataset[count].pointHoverRadius = 0;
            if (chartType === 'hundred' && labelsLength < dataset[count].data.length)
              labelsLength = dataset[count].data.length;
          }
          count++;
        }
      });

      //Dataset for variable region
      let selectRegionIndex = lineData.findIndex((data) => data.country === highlight.value);
      if (variableData.country  &&  (selectRegionIndex === -1 || selectRegionIndex >= parentChoice.value)) {
        dataset[26].data = variableData.data;
        dataset[26].label = variableData.country;
        dataset[26].dates = variableData.dates;
        dataset[26].showLine = true;
        dataset[26].borderWidth = 1;
        dataset[26].pointRadius = 1.5;
        dataset[26].pointHoverRadius = 1.5;
        dataset[26].borderColor = colors.red;
        if (chartType === 'hundred' && labelsLength < dataset[26].data.length) labelsLength = dataset[26].data.length;
      }
      if (chartType === 'hundred') chartInstance.data.labels = [...Array(labelsLength).keys()];

      chartInstance.update();
    }
  };

  const checkDate = (recordDate, cutoffDate) => {
    let recordDateArr = recordDate.split('-');
    let cutoffDateArr = cutoffDate.split('-');

    var d1 = Number(recordDateArr[2] + recordDateArr[0] + recordDateArr[1]);
    var d2 = Number(cutoffDateArr[2] + cutoffDateArr[0] + cutoffDateArr[1]);
    return d1 > d2;
  };

  const getFormattedDateLabel = (date) => {
    var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    let dateArr = date.split('-');
    return mS[Number(dateArr[0] - 1)] + ' ' + dateArr[1];
  };

  // Format data to update in chart dataset
  const data = useMemo(() => {
    let labels = [];
    let lineData = [];
    let variableData = {};
    let flag = false;

    if (topCountiesData.data) {
      _.forEach(topCountiesData.data.subRegions, (value) => {
        let count = 0;
        let temp_data = { data: [], country: value['region'].split(':')[2], dates: [] };
        let new_data = value.stat.sort(function (a, b) {
          let aArr = a.day.split('-');
          let bArr = b.day.split('-');

          var d1 = Number(aArr[2] + aArr[0] + aArr[1]);
          var d2 = Number(bArr[2] + bArr[0] + bArr[1]);
          return d1 == d2 ? 0 : +(d1 > d2) || -1;
        });
        _.forEach(new_data, (value1, index) => {
          let selectedOption = option ? option : 'Confirmed';
          if (chartType === 'hundred') {
            if (value1.stat[optionConfig[selectedOption].param] >= 20) {
              temp_data['dates'].push(getFormattedDateLabel(value1.day));
              temp_data['data'].push(value1.stat[optionConfig[selectedOption].param]);
            }
            count++;
          }
          if (chartType === 'regular') {
            if (!flag) {
              labels.push(getFormattedDateLabel(value1['day']));
            }
            temp_data['data'].push(value1.stat[optionConfig[selectedOption].param]);
          }
        });
        flag = true;
        lineData.push(temp_data);
      });
    }

    // variable data based on county selection
    if (regionData.data && typeof regionData.data['region'] === 'string') {
      variableData = { data: [], country: regionData.data['region'].split(':')[2], dates: [] };
      let new_data = regionData.data.stat.sort(function (a, b) {
        let aArr = a.day.split('-');
        let bArr = b.day.split('-');

        var d1 = Number(aArr[2] + aArr[0] + aArr[1]);
        var d2 = Number(bArr[2] + bArr[0] + bArr[1]);
        return d1 == d2 ? 0 : +(d1 > d2) || -1;
      });
      let sum = 0
      _.forEach(new_data, (value, index) => {
        let selectedOption = option ? option : 'Confirmed';
        if(fetchDataType !== 'new') {
          if (chartType === 'hundred') {
            if (value.stat[optionConfig[selectedOption].param] >= 20) {
              variableData['dates'].push(getFormattedDateLabel(value.day));
              variableData['data'].push(value.stat[optionConfig[selectedOption].param]);
            }
          }
          // if (chartType === 'regular') {
          //   variableData['data'].push(value.stat[optionConfig[selectedOption].param]);
          // }
        }
        else{
          if (chartType === 'hundred') {
            if (sum < 20) {
              sum = sum + value.stat[optionConfig[selectedOption].param]
            }
            if (sum >= 20) {
              variableData['dates'].push(getFormattedDateLabel(value.day));
              variableData['data'].push(value.stat[optionConfig[selectedOption].param]);
            }
          }
        }
      });
    }
    return { labels, lineData, variableData };
  }, [topCountiesData.data, regionData.data]);

  useEffect(() => {
    const { lineData, variableData } = data;
    let legend_list = [];
    for (let i = 0; i < parentChoice.value; i++) {
      if (lineData.length > 0) {
        if (lineData[i].data && lineData[i].data.length > 0)
          legend_list.push(lineData[i].country);
      }
    }
    if (variableData.hasOwnProperty('country') && variableData.country)
      if (!legends.includes(variableData.country)) {
        if (variableData.data && variableData.data.length > 0)
          legend_list.push(variableData.country);
      }

    setLegends(legend_list);
  }, [data, parentChoice]);

  useEffect(() => {
    if (topCountiesData.data) {
      const { labels, lineData, variableData } = data;
      updateDataset(labels, lineData, variableData);
    }

    const handleOrientationChange = () => {
      if (window.matchMedia('(orientation: portrait)').matches) {
        setOrientation('portrait');
      }

      if (window.matchMedia('(orientation: landscape)').matches) {
        setOrientation('landscape');
      }
      if (chartInstance) {
        // firstTimeScroll = false;
        chartInstance.update();
      }
    };

    window.addEventListener('orientationchange', handleOrientationChange);
    return () => window.removeEventListener('orientationchange', handleOrientationChange);
  }, [data, option, parentChoice, highlight, parentAxis, chartView, parentUsstate, chartType]);

  const mouseOver = (event) => {
    if (event.target.id) {
      let elem = document.getElementById(event.target.id);
      if (elem) elem.style.backgroundColor = '#33bc9d';
      let index = Number(event.target.id.split('-')[1]);
      if (index > 0) {
        if (highlightData !== null && highlightData.hasOwnProperty('indexing')) {
          chartInstance.data.datasets[0] = chartInstance.data.datasets[highlightData.indexing];
          chartInstance.data.datasets[highlightData.indexing] = highlightData;
          chartInstance.data.datasets[highlightData.indexing].borderWidth = 1;
        }
        setHighLightData(chartInstance.data.datasets[index]);
        let arr = chartInstance.data.datasets[0];
        chartInstance.data.datasets[0] = chartInstance.data.datasets[index];
        chartInstance.data.datasets[0].borderWidth = 5;
        chartInstance.data.datasets[index] = arr;
        chartInstance.update();
      }
    }
  };

  const mouseOut = (event) => {
    if (event.target.id) {
      let elem = document.getElementById(event.target.id);
      if (elem) elem.style.backgroundColor = '#000';
    }
  };

  const devWidth = window.innerWidth - 35;
  let showLegends = () => {
    setShowLegend(!showLegend);
  };

  return (
    <div>
      <div className='chart-title font-extrabold text-2xl text-sharecare-darkgreen leading-none'>
        Top Counties
        <p style={{ fontSize: '12px', fontWeight: 500 }}>(Days since 20 cases)</p>
      </div>
      <div className='flex justify-end' style={{ padding: '0 20px', position: 'relative', float: 'right' }}>
        <button className='chart-button' onClick={showLegends}>
          {showLegend ? `Hide Legend` : `Show Legend`}
        </button>
        <div style={{ position: 'absolute', top: 40, right: 10 }}>
          {showLegend && (
            <div className='chart-legend-div hide-native-scrollbar'>
              {chartInstance && (
                <>
                  {map(legends, (dataset, key) => (
                    <div key={key}>
                      <Legend
                        chartName='County'
                        current={key + 1 === parentChoice.value + 1 ? 26 : key + 1}
                        color={key + 1 === parentChoice.value + 1 ? colors.red : colors.lineColorPallete[key + 1]}
                        label={dataset}
                        mouseOver={mouseOver}
                        mouseOut={mouseOut}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className='mt-10 flex chartSpacing'>
        <div className='chartWrapper' id='wrapper'>
          <div className='chartAreaWrapper' id='county-areaWrapper' style={{ width: devWidth }}>
            <div className='chartAreaWrapperglobal' id='county-wrapper2'>
              <canvas id='county-chart-Test' height='400' width='1500' ref={chartContainer}></canvas>
            </div>
          </div>
          <canvas id='county-axis-A' height='400' width='0' style={{ backgroundColor: 'black' }}></canvas>
        </div>
      </div>
    </div>
  );
};

export default CountyChart;
