import React from "react";
import { Source, Layer } from "react-map-gl";
import colors from "/domain/colors";

export default function HighlightCounty({
  countyLayerId,
  countySourceId,
  countySourceLayer,
  hoverIdentifier,
  beforeId,
  availableFeatures,
}) {
  const hoverIdentifier1 = hoverIdentifier || null;
  return (
    <Source
      id={countySourceId}
      type="vector"
      url="mapbox://mapbox.boundaries-adm2-v3"
    >
      <Layer
        id={countyLayerId}
        beforeId={beforeId}
        source-layer={countySourceLayer}
        type="fill"
        minzoom={5}
        paint={{
          "fill-opacity": [
            "case",
            ["in", ["id"], ["literal", availableFeatures]],
            0.5,
            0.1,
          ],
          "fill-outline-color": [
            "case",
            [
              "boolean",
              [
                "match",
                ["get", "iso_3166_1"],
                ["CA", "CN", "AU", "US"],
                true,
                false,
              ],
            ],
            "white",
            "transparent",
          ],
          "fill-color": [
            "case",
            ["==", ["id"], hoverIdentifier1],
            colors.darkgreen,
            colors['map-background']
          ],
        }}
      />
    </Source>
  );
}
