import React, { useMemo, useContext, useRef, useEffect } from "react";
import _ from "lodash";
import produce from "immer";
import AppStateContext from "../contexts/AppState";
import statsConfig from "../domain/stats";
import { ChevronDown, ChevronUp } from "./icons";
import {
  getPercentage,
  numberWithCommas,
  mapValueInRange,
  formatNumber,
  getStatName
} from "../util";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

function getBarWidth(max, num) {
  const percentage = getPercentage(max, num);
  if (percentage === 0) return 0;
  return Math.max(1, percentage * 0.9);
}

function getMaximums(stats, statName) {
  return _.reduce(
    stats,
    (acc, data) => {
      acc.dead = Math.max(acc.dead, _.get(data, `${statName}.dead`, 0));
      acc.active = Math.max(acc.active, _.get(data, `${statName}.active`, 0));
      acc.recovered = Math.max(acc.recovered, _.get(data, `${statName}.recovered`, 0));
      acc.confirmed = Math.max(acc.confirmed, _.get(data, `${statName}.confirmed`, 0));
      return acc;
    },
    {
      dead: 0,
      active: 0,
      recovered: 0,
      confirmed: 0,
    }
  );
}

function Column({ children, className }) {
  return <div className={`stat-column ${className}`}>{children}</div>;
}

export function StatsTableInner({
  data,
  filteredStatsConfig,
  appState,
  maximums,
  isMobile,
  hovered,
}) {
  const statName = getStatName(appState.toggleType === "capita")
  const regionType = _.get(data, "type");
  return (
    <>
      {_.map(data.sortedSubStats, (statData, idx) => {
        const mapJoinValue = _.get(statData, "mapJoinValue");
        return (
          <div
            key={idx}
            onMouseEnter={(e) => {
              appState.actions.setCurrentMapHoveredFeature({
                identifier: mapJoinValue,
                type: regionType,
              });
            }}
            onMouseLeave={(e) => {
              appState.actions.setCurrentMapHoveredFeature({});
            }}
            className={`${!isMobile ? "flex justify-end" : "text-sm"} ${
              hovered && hovered === mapJoinValue
                ? "bg-sharecare-darkgreen-semi"
                : ""
            } p-2 relative rounded transition-all duration-150 ease hover:bg-sharecare-darkgreen-semi cursor-pointer`}
            onClick={() => appState.actions.drillDown(statData.name)}
          >
            <div
              className={`${
                !isMobile ? "text-right" : "underline inline-flex"
              } text-white font-semibold truncate name-column`}
            >
              {_.get(statData, "name")}
            </div>
            <div className="flex">
              {_.map(filteredStatsConfig, (stat) => {
                const barWidth = getBarWidth(
                  _.get(maximums, stat.id),
                  _.get(statData, [statName, stat.id])
                );
                const opacity = mapValueInRange(barWidth, 1, 90, 0.4, 1);
                const value = _.get(statData, [statName, stat.id])
                return (
                  <Column key={stat.id} className="relative">
                    <div
                      className={`rounded absolute top-0 bottom-0 right-0 transition-all duration-300 ease-in-out opacity-75 bg-sharecare-${stat.color}`}
                      style={{
                        opacity: opacity,
                        width: `${barWidth}%`,
                      }}
                    ></div>
                    <div className="relative text-white text-right font-semibold font-tabular-nums pr-3">
                      {numberWithCommas(_.get(statData, [statName, stat.id]))}
                    </div>
                  </Column>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default function StatsTable({ isMobile }) {
  const appState = useContext(AppStateContext);
  const statName = getStatName(appState.toggleType === "capita")
  const appStateData = _.get(appState, "data");
  const filteredStatsConfig = _.filter(statsConfig, (stat) =>
    _.get(appState, ["stats", stat.id])
  );
  const sortField = _.get(appState, "sort.field");
  const sortDirection = _.get(appState, "sort.direction");
  const data = useMemo(() => {
    return produce(_.get(appState, "data"), (draftData) => {
      const subStats = draftData.subStats;
      if (subStats) {
        draftData.sortedSubStats = _.sortBy(subStats, (s) => {
          if (sortField === "sortableName") {
            const sortValue = _.get(s, "sortableName")
            return sortValue === undefined ? 0 : sortValue
          } else {
            const sortValue = _.get(s, [statName, sortField])
            return sortValue === undefined ? 0 : sortValue
          }
        });
        if (-1 === sortDirection) {
          draftData.sortedSubStats.reverse()
        }
      }
    });
  }, [sortField, sortDirection, appStateData, appState.toggleType]);
  const maximums = useMemo(() => {
    return getMaximums(_.get(data, "sortedSubStats"), statName);
  }, [data.sortedSubStats]);
  const tableRef = useRef(null);
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.recalculate();
      tableRef.current.getScrollElement().scrollTop = 0;
    }
  }, [appStateData]);

  const currentMapHoveredFeature = _.get(appState, "currentMapHoveredFeature");
  // Coerce null to false to ensure that we're not matching null to null
  const hovered =
    (_.get(currentMapHoveredFeature, "type") === _.get(appState, "data.type")
      ? _.get(currentMapHoveredFeature, "identifier")
      : false) || false;

  return (
    <div
      className={`flex flex-col min-h-0 w-full mr-2 stats-count-${filteredStatsConfig.length}`}
    >
      <div className="flex justify-end" style={{ minHeight: "50px" }}>
        {!isMobile ? (
          <Column className="text-gray-500 uppercase name-column text-right leading-tight">
            <div className="text-2xl font-extrabold">{"\u00A0"}</div>
            <div
              className="text-sm font-semibold flex items-start justify-end leading-none cursor-pointer"
              onClick={() => {
                appState.actions.setSortField("sortableName");
              }}
            >
              Name
              {sortField === "sortableName" && sortDirection === 1 ? (
                <ChevronDown className="h-6 -mt-1 -mr-1" />
              ) : null}
              {sortField === "sortableName" && sortDirection === -1 ? (
                <ChevronUp className="h-6 -mt-1 -mr-1" />
              ) : null}
            </div>
          </Column>
        ) : null}
        {_.map(filteredStatsConfig, (stat) => {
          return (
            <Column
              key={stat.id}
              className={`text-sharecare-${stat.color} uppercase text-right leading-tight`}
            >
              <div
                className={`${
                  isMobile ? "text-1-5xl" : "text-2xl"
                } font-extrabold`}
              >
                {appState.toggleType === "normal"
                  ? formatNumber(_.get(data, [statName, stat.id]), isMobile)
                  : appState.parentRegionName
                  ? formatNumber(_.get(data, [statName, stat.id]), isMobile)
                  : "\u00A0"}
              </div>
              <div
                className={`${
                  isMobile ? "text-1-5xs" : "text-sm"
                } font-semibold flex items-start justify-end leading-none cursor-pointer`}
                onClick={() => {
                  appState.actions.setSortField(stat.id);
                }}
              >
                {stat.label}
                {sortField === stat.id && sortDirection === 1 ? (
                  <ChevronDown
                    className={isMobile ? "h-4 -mt-1" : "h-6 -mt-1 -mr-1"}
                  />
                ) : null}
                {sortField === stat.id && sortDirection === -1 ? (
                  <ChevronUp
                    className={isMobile ? "h-4 -mt-1" : "h-6 -mt-1 -mr-1"}
                  />
                ) : null}
              </div>
            </Column>
          );
        })}
      </div>
      {isMobile ? (
        <div className="-mr-2">
          <StatsTableInner
            data={data}
            appState={appState}
            filteredStatsConfig={filteredStatsConfig}
            maximums={maximums}
            isMobile={isMobile}
            hovered={hovered}
          />
        </div>
      ) : (
        <SimpleBar
          ref={tableRef}
          forceVisible="y"
          autoHide={false}
          style={{ maxHeight: "calc(100% - 14px)", marginRight: "-1.5rem" }}
        >
          <div className="stat-panel min-h-0 mr-4 overflow-x-hidden">
            <StatsTableInner
              data={data}
              appState={appState}
              filteredStatsConfig={filteredStatsConfig}
              maximums={maximums}
              hovered={hovered}
            />
          </div>
        </SimpleBar>
      )}
    </div>
  );
}
