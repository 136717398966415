import React, { useState, useMemo, useReducer, useEffect } from "react";
import produce from "immer";
import _ from "lodash";
import { useQuery } from "react-query";
import { getRegion } from "../domain/api";
import statsConfig from "../domain/stats";

const AppStateContext = React.createContext({});

const { Provider } = AppStateContext;

function interpose(arr, el) {
  var lastIndex = arr.length - 1;
  return _.reduce(
    arr,
    (res, x, index) => {
      res.push(x);
      if (lastIndex !== index) res.push(el);
      return res;
    },
    []
  );
}

function butlast(arr) {
  if (arr && arr.length > 1) return arr.slice(0, arr.length - 1);
  return [];
}

function toggleStat(appState, stat, checked) {
  return produce(appState, (draftState) => {
    _.set(draftState, ["stats", stat], checked);
    if (_.get(draftState, "sort.field") === stat) {
      const stats = _.get(draftState, "stats", {});
      for (const stat in stats) {
        if (stats[stat]) {
          draftState.sort = { field: stat, direction: -1 };
          return;
        }
      }
    }
  });
}

function setSortField(appState, field) {
  return produce(appState, (draftState) => {
    const currentField = _.get(draftState, "sort.field");
    if (currentField === field) {
      _.update(draftState, "sort.direction", (v) => v * -1);
    } else if (field === "sortableName") {
      // If we are switching to sort by name, we want to sort
      // A-Z first time
      draftState.sort = { field, direction: 1 };
    } else {
      // Otherwise we sort with the biggest value on top
      draftState.sort = { field, direction: -1 };
    }
  });
}

function drillDown(appState, name) {
  return produce(appState, (draftState) => {
    if (!draftState.path.length) {
      let currentSortField = _.get(draftState, "sort.field");
      draftState.globalBackup = {
        stats: { ...draftState.stats },
        sort: { ...draftState.sort },
      };
      draftState.stats.active = false;
      draftState.stats.recovered = false;
      if (currentSortField === "active" || currentSortField === "recovered") {
        draftState.sort = { field: "confirmed", direction: -1 };
      }
    }
    draftState.path.push(name);
  });
}

function goBack(appState) {
  return produce(appState, (draftState) => {
    if (draftState.path.length === 1 && draftState.globalBackup) {
      draftState.sort = draftState.globalBackup.sort;
      draftState.stats = draftState.globalBackup.stats;
      delete draftState.globalBackup;
      if (draftState.dataType === "new") {
        draftState.stats.active = false;
      }
    }
    draftState.path.pop();
  });
}

function toggleDataType(appState, typePage, optionTag = "") {
  return produce(appState, (draftState) => {
    // For per capita toggle
    if (typePage === "capita")
      draftState.toggleType =
        draftState.toggleType === "normal" ? "capita" : "normal";

    if (typePage === "new") {
      const newDataType =
        draftState.dataType === "cumulative" ? "new" : "cumulative";
      if (newDataType === "new") {
        draftState.backupActive = draftState.stats.active;
        draftState.stats.active = false;
      } else {
        draftState.stats.active =
          draftState.backupActive || draftState.stats.active;
        delete draftState.backupActive;
      }
      draftState.dataType = newDataType;
    }
    if (typePage === "charts" && optionTag.includes("Daily")) {
      const newDataType = "new";
      draftState.dataType = newDataType;
    }
    if (typePage === "charts" && !optionTag.includes("Daily")) {
      const newDataType = "cumulative";
      draftState.dataType = newDataType;
    }

    // We can simplify this operation
    if (typePage === "hundred") {
      const newChartType = draftState.chartType === "regular" ? "hundred": "regular";
      draftState.chartType = newChartType;
    }
  });
}

function setSidebarWidth(appState, width) {
  return produce(appState, (draftState) => {
    draftState.sidebarWidth = width;
  });
}

function setMapLoaded(appState) {
  return produce(appState, (draftState) => {
    draftState.mapLoaded = true;
  });
}

function setPage(appState, page) {
  return produce(appState, (draftState) => {
    draftState.page = page;
  });
}

function setPath(appState, path) {
  return produce(appState, (draftState) => {
    draftState.path = path;
  });
}

function toggleMapRightPanelHidden(appState) {
  return produce(appState, (draftState) => {
    draftState.mapRightPanelHidden = !draftState.mapRightPanelHidden;
  });
}

function setInitDone(appState) {
  return produce(appState, (draftState) => {
    draftState.initDone = true;
  });
}

function setMobileTab(appState, page, tab) {
  return produce(appState, (draftState) => {
    draftState.mobileTab[page] = tab;
  });
}

function setViewport(appState, newViewport) {
  return produce(appState, (draftState) => {
    draftState.viewport = newViewport;
  });
}

function initialMapZoomDone(appState) {
  return produce(appState, (draftState) => {
    draftState.initialMapZoomDone = true;
  });
}

function setCurrentMapHoveredFeature(appState, hoveredFeature) {
  return produce(appState, (draftState) => {
    draftState.currentMapHoveredFeature = hoveredFeature;
  });
}

function setChartView(appState, chartView){
  return produce(appState, (draftState) => {
    draftState.chartView = chartView;
  });
}

const urlParams = new URLSearchParams(window.location.search);
const country = urlParams.get("country");
const state = urlParams.get("state");
const mapType = urlParams.get("mapType");
let path = [];
country && path.push(country);
state && path.push(state);
const initialState = produce(
  {
    viewport: {
      latitude: 90,
      longitude: 0,
      zoom: 0,
    },
    currentMapHoveredFeature: {},
    initialMapZoomDone: false,
    mobileTab: { map: "map" },
    initDone: false,
    mapRightPanelHidden: true,
    page: mapType ? mapType : "map",
    sidebarWidth: 0,
    chartView: "Global",
    chartType: "regular",
    dataType: "cumulative",
    toggleType: "normal",
    stats: _.reduce(
      statsConfig,
      (availableStats, stat) => {
        path.length && (stat.id === 'active' || stat.id === 'recovered') ?
          availableStats[stat.id] = false : availableStats[stat.id] = true
        if(mapType === "usCountyAnimation" && stat.id === 'dead') availableStats[stat.id] = false
        return availableStats;
      },
      {}
    ),
    path: path,
    sort: { field: "confirmed", direction: -1 },
  },
  _.identity
);

const ACTIONS = {
  toggleStat,
  toggleDataType,
  setSortField,
  drillDown,
  goBack,
  setSidebarWidth,
  setMapLoaded,
  setPage,
  setPath,
  toggleMapRightPanelHidden,
  setInitDone,
  setMobileTab,
  setViewport,
  initialMapZoomDone,
  setCurrentMapHoveredFeature,
  setChartView
};

function reducer(state, action) {
  let actionReducer = ACTIONS[action.type];
  if (actionReducer) {
    return actionReducer.apply(null, [state].concat(action.payload));
  } else {
    return state;
  }
}

const appStateRef = { current: null };

AppStateContext.Provider = ({ children }) => {
  const [appState, dispatch] = useReducer(reducer, initialState);

  // appState is immutable, and sometimes we need to grab the current
  // one, for instance from setTimeout
  appStateRef.current = appState;

  const region = useQuery(
    [
      "region",
      { dataType: appState.dataType },
    ],
    getRegion
  );

  const currentData = useMemo(() => {
    const path = appState.path;
    if (path.length) {
      return _.get(
        region.data,
        ["subStats"].concat(interpose(path, "subStats")),
        {}
      );
    }
    return region.data || {};
  }, [appState.path, region.data]);

  const parentRegionName = useMemo(() => {
    const path = appState.path;
    if (path && path.length) {
      const parentPath = butlast(path);
      if (parentPath && parentPath.length) {
        return _.get(
          region.data,
          ["subStats"]
            .concat(interpose(parentPath, "subStats"))
            .concat(["name"])
        );
      }
      return "Global";
    }
  }, [appState.path, region.data]);

  const actions = _.reduce(
    ACTIONS,
    (acc, val, k) => {
      acc[k] = function () {
        let args = [];
        for (let i = 0; i < arguments.length; i++) {
          args.push(arguments[i]);
        }
        dispatch({ type: k, payload: args });
      };
      return acc;
    },
    {}
  );

  useEffect(() => {
    actions.setCurrentMapHoveredFeature({});
  }, [appState.path]);

  return (
    <Provider
      value={{
        ...appState,
        parentRegionName,
        data: currentData,
        mapData: currentData.subStats || { [currentData.name]: currentData },
        getCurrent: () => {
          return appStateRef.current;
        },
        actions: actions,
      }}
    >
      {children}
    </Provider>
  );
};

export default AppStateContext;
