import React, { useEffect, useRef, useState, useContext } from "react";
import _ from "lodash";
import { mapValueInRange, getStatName } from "../util";
import { getBBox, getPolygonBBox } from "../domain/api";
import HighlightCountry from "./layers/HighlightCountry";
import HighlightCounty from "./layers/HighlightCounty";
import HighlightAdmin4 from "./layers/HighlightAdmin4";
import countryBBoxes from "/domain/countryBBoxes";
import ReactMapGL, {
  FlyToInterpolator,
  WebMercatorViewport,
  NavigationControl,
} from "react-map-gl";
import { easeCubic, easeCubicOut } from "d3-ease";
import MapMarkers from "./MapMarkers";
import AppStateContext from "../contexts/AppState";
const { MAPBOX_TOKEN } = require('/domain/tokens.json')

function logFeatures(map, e) {
  var features = map.queryRenderedFeatures(e.point);

  // Limit the number of properties we're displaying for
  // legibility and performance
  var displayProperties = [
    "type",
    "properties",
    "id",
    "layer",
    "source",
    "sourceLayer",
    "state",
  ];

  var displayFeatures = features.map(function (feat) {
    var displayFeat = {};
    displayProperties.forEach(function (prop) {
      displayFeat[prop] = feat[prop];
    });
    return displayFeat;
  });

  console.log(
    "**********DISPLAY FEATURES************",
    JSON.stringify(displayFeatures)
  );
}

const log = (data) => {
  console.log("************DATA************", data);
  return data;
};

const customSharecareStyle =
  "mapbox://styles/sharecare/ck8t4ny8301tq1it6qgq9oeb8";

function mapHasLayer(map, layerId) {
  return !!_.get(map, ["style", "_layers", layerId]);
}

function getMarkerData({ mapData, stats, toggleType }) {
  if (!mapData) return [];

  const hasConfirmed = _.get(stats, "confirmed");
  const hasActive = _.get(stats, "active");
  const hasRecovered = _.get(stats, "recovered");
  const hasDead = _.get(stats, "dead");
  const isSingle = _.values(mapData).length === 1;
  const statName = getStatName(toggleType === "capita")
  const siblingTotalMinName = toggleType === "capita" ? "perCapitaSiblingTotalMin" : "siblingTotalMin"
  const siblingTotalMaxName = toggleType === "capita" ? "perCapitaSiblingTotalMax" : "siblingTotalMax"

  const getConfirmed = (d) => {
    return hasConfirmed ? _.get(d, `${statName}.confirmed`) : 0;
  };
  const getActive = (d) => {
    return hasActive ? _.get(d, `${statName}.active`) : 0;
  };
  const getRecovered = (d) => {
    return hasRecovered ? _.get(d, `${statName}.recovered`) : 0;
  };
  const getDead = (d) => {
    return hasDead ? _.get(d, `${statName}.dead`) : 0;
  };
  const getTotal = (d) => _.get(d, `${statName}.confirmed`);

  return _.orderBy(
    _.filter(
      _.map(mapData, (d) => {
        const total = getTotal(d);
        const confirmed = getConfirmed(d);
        return {
          key: _.get(d, "name"),
          name: _.get(d, "name"),
          mapJoinValue: _.get(d, "mapJoinValue"),
          countryCode: _.get(d, "region.code2"),
          hasSubstats: !!_.get(d, "subStats"),
          isSingle: isSingle,
          latitude: _.get(d, "region.latitude"),
          longitude: _.get(d, "region.longitude"),
          activeOriginal: _.get(d, `${statName}.active`),
          recoveredOriginal: _.get(d, `${statName}.recovered`),
          deadOriginal: _.get(d, `${statName}.dead`),
          confirmedOriginal: _.get(d, `${statName}.confirmed`),
          confirmed: confirmed,
          active: getActive(d),
          recovered: getRecovered(d),
          dead: getDead(d),
          total: _.get(d, `${statName}.confirmed`),
          dimension: mapValueInRange(
            total,
            _.get(d, siblingTotalMinName),
            _.get(d, siblingTotalMaxName),
            50,
            250
          ),
        };
      }),
      (d) => !!d && !!d.latitude && !!d.longitude
    ),
    ["total"],
    ["desc"]
  );
}

const worldBBox = [
  [-179, -60],
  [179, 85],
];

function getPadding(isMobile, sidebarWidth) {
  if (isMobile) {
    return { top: 50, left: 20, right: 20, bottom: 20 };
  }
  return { top: 100, left: 50, right: 50 /* + sidebarWidth*/, bottom: 50 };
}

function zoomOnArea({
  data,
  isMobile,
  sidebarWidth,
  viewport,
  setViewport,
  transitionDuration,
  mapRef,
}) {
  let bbox;

  if (_.isEmpty(data)) return;

  if (data.type === "country") {
    if (mapRef && mapRef.current) {
      const map = mapRef.current.getMap();
      const countriesPolygons = map.querySourceFeatures(countySourceId, {
        sourceLayer: countySourceLayer,
      });
      bbox = getBBox(data, countriesPolygons);
    } else {
      // try to get cached value
      //TODO: should be a more graceful way to pass this...don't hardcode US
      bbox = data.name === "US" ? countryBBoxes.US : getPolygonBBox(data);
    }
  } else {
    bbox = getBBox(data);
  }

  let newViewport = {
    latitude: _.get(data, "region.latitude", 0),
    longitude: _.get(data, "region.longitude", 0),
  };

  if (_.get(data, "region") && !newViewport.latitude && !newViewport.longitude)
    return;

  if (bbox) {
    newViewport = new WebMercatorViewport(viewport).fitBounds(bbox, {
      padding: getPadding(isMobile, sidebarWidth)
    });
  } else if (data.type === "state") {
    newViewport.zoom = Math.max(viewport.zoom, 6);
  } else if (data.type === "county") {
    newViewport.zoom = Math.max(viewport.zoom, 8);
  } else if (data.type === "Global") {
    newViewport = new WebMercatorViewport(viewport).fitBounds(worldBBox, {
      padding: 0,
    });
  }

  let viewportSettings = {
    ...viewport,
    ...newViewport,
    transitionDuration: _.isNil(transitionDuration)
      ? "auto"
      : transitionDuration,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: easeCubic,
  }
  setViewport(viewportSettings);
}

let zoomOnAreaTimeout;

function useZoomOnArea(appState, mapRef, viewport, setViewport, isMobile) {
  const [firstRenderDone, setFirstRenderDone] = useState(false);
  const mapLoaded = _.get(appState, "mapLoaded");
  const data = _.get(appState, "data");
  useEffect(() => {
    if (firstRenderDone && mapLoaded) {
      clearTimeout(zoomOnAreaTimeout);
      zoomOnAreaTimeout = setTimeout(() => {
        // appState is immutable, so we get the current one to read
        // the sidebar width that changed during the setTimeout wait
        const currentAppState = appState.getCurrent();
        const sidebarWidth = _.get(currentAppState, "sidebarWidth", 0);
        zoomOnArea({
          data,
          isMobile,
          sidebarWidth,
          viewport,
          setViewport,
          mapRef,
        });
      }, 150);
    } else if (mapLoaded && !_.isEmpty(data)) {
      setFirstRenderDone(true);
    }
  }, [_.get(appState, "path"), mapLoaded, _.isEmpty(data)]);
}

function useInitialZoom(appState, viewport, setViewport, isMobile) {
  const mapLoaded = _.get(appState, "mapLoaded");
  useEffect(() => {
    // When the map is loaded, move to a "world" viewport
    if (mapLoaded) {
      const data = _.get(appState, "data");
      const sidebarWidth = _.get(appState, "sidebarWidth", 0);
      appState.actions.initialMapZoomDone();
      zoomOnArea({
        data,
        isMobile,
        sidebarWidth,
        viewport,
        setViewport,
        transitionDuration: _.get(appState, "initialMapZoomDone") ? 1 : 1000,
      });
    }
  }, [mapLoaded]);
}

const countrySourceId = "country-boundaries";
const countryLayerId = "highlight-countries";
const countrySourceLayer = "boundaries_admin_0";

const admin4SourceId = "admin4";
const admin4LayerId = "highlight-admin4";
const admin4SourceLayer = "boundaries_admin_1";

const countySourceId = "counties";
const countyLayerId = "highlighted-counties";
const countySourceLayer = "boundaries_admin_2";

const SOURCE_LAYER_BY_REGION_TYPE = {
  Global: {
    sourceId: countrySourceId,
    layerId: countryLayerId,
    sourceLayer: countrySourceLayer,
  },
  country: {
    sourceId: admin4SourceId,
    layerId: admin4LayerId,
    sourceLayer: admin4SourceLayer,
  },
  state: {
    sourceId: countySourceId,
    layerId: countyLayerId,
    sourceLayer: countySourceLayer,
  },
};

export default function Map({ isMobile }) {
  const appState = useContext(AppStateContext);
  const mapLoaded = _.get(appState, "mapLoaded");
  const viewport = _.get(appState, "viewport");
  const setViewport = appState.actions.setViewport;
  const data = _.get(appState, "data");
  const mapClickDisabled = useRef(false);

  const [firstSymbolLayerId, setFirstSymbolLayerId] = useState(null);

  const currentHoverFeature = _.get(appState, "currentMapHoveredFeature");
  const setCurrentHoverFeature = _.get(
    appState,
    "actions.setCurrentMapHoveredFeature"
  );

  const mapRef = useRef(null);

  useInitialZoom(appState, viewport, setViewport, isMobile);
  useZoomOnArea(appState, mapRef, viewport, setViewport, isMobile);

  const markerData = getMarkerData(appState);
  const availableFeatures = _.map(_.get(data, "subStats"), "mapJoinValue");
  const availableFeaturesSet = new Set(availableFeatures);
  const currentSourceLayerByRegionType =
    SOURCE_LAYER_BY_REGION_TYPE[_.get(data, "type")];

  const hoverTouchStartFindFeature = (e) => {
    const map = mapRef.current && mapRef.current.getMap();
    if (
      map &&
      currentSourceLayerByRegionType &&
      mapHasLayer(map, _.get(currentSourceLayerByRegionType, "layerId"))
    ) {
      const feature = map.queryRenderedFeatures(e.point, {
        layers: [currentSourceLayerByRegionType.layerId],
      })[0];
      const regionType = _.get(data, "type");
      const featureId = _.get(feature, "id");

      let identifier;

      if (regionType === "Global") {
        identifier = _.get(feature, ["properties", "iso_3166_1"]);
      } else {
        identifier = featureId;
      }

      return { identifier, type: regionType };
    }
    return {};
  };

  return (
    <div className="absolute inset-0">
      <ReactMapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle={customSharecareStyle}
        onViewportChange={setViewport}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        ref={(m) => (mapRef.current = m)}
        onLoad={(e) => {
          const map = e.target;
          const layers = map.getStyle().layers;
          for (let i = 0; i < layers.length; i++) {
            let current = layers[i];
            if (
              current.type === "symbol" &&
              current.id !== firstSymbolLayerId
            ) {
              setFirstSymbolLayerId(current.id);
              break;
            }
          }

          appState.actions.setMapLoaded(true);
        }}
        onClick={(e) => {
          if (mapClickDisabled.current) return;

          const map = mapRef.current && mapRef.current.getMap();

          if (map && currentSourceLayerByRegionType) {
            const feature = map.queryRenderedFeatures(e.point, {
              layers: [currentSourceLayerByRegionType.layerId],
            })[0];

            const regionType = _.get(data, "type");
            const featureId = _.get(feature, "id");

            const datum = _.filter(data.subStats, (datum) => {
              if (regionType === "Global") {
                return (
                  datum.mapJoinValue ===
                  _.get(feature, ["properties", "iso_3166_1"])
                );
              } else {
                return datum.mapJoinValue === featureId;
              }
            })[0];

            if (datum) {
              appState.actions.drillDown(datum.name);
            }
          }
        }}
        onMouseMove={(e) => {
          const { identifier, type } = hoverTouchStartFindFeature(e);
          if (identifier && availableFeaturesSet.has(identifier)) {
            setCurrentHoverFeature({
              identifier,
              type,
            });
          } else if (!_.isEqual(currentHoverFeature, {})) {
            setCurrentHoverFeature({});
          }
        }}
        onTouchStart={(e) => {
          const currentIdentifier = _.get(currentHoverFeature, "identifier");
          const { identifier, type } = hoverTouchStartFindFeature(e);
          if (
            identifier &&
            availableFeaturesSet.has(identifier) &&
            identifier !== currentIdentifier
          ) {
            setCurrentHoverFeature({
              identifier,
              type,
            });
          } else if (!_.isEqual(currentHoverFeature, {})) {
            setCurrentHoverFeature({});
          }
        }}
      >
        <HighlightCountry
          beforeId={firstSymbolLayerId}
          countrySourceLayer={countrySourceLayer}
          countryLayerId={countryLayerId}
          countrySourceId={countrySourceId}
          availableFeatures={availableFeatures}
          hoverIdentifier={
            _.get(currentHoverFeature, "type") === "Global" &&
            _.get(currentHoverFeature, "identifier")
          }
        />
        <HighlightCounty
          beforeId={firstSymbolLayerId}
          countySourceLayer={countySourceLayer}
          countyLayerId={countyLayerId}
          countySourceId={countySourceId}
          availableFeatures={availableFeatures}
          hoverIdentifier={
            _.get(currentHoverFeature, "type") === "state" &&
            _.get(currentHoverFeature, "identifier")
          }
        />
        <HighlightAdmin4
          beforeId={firstSymbolLayerId}
          admin4SourceLayer={admin4SourceLayer}
          admin4LayerId={admin4LayerId}
          admin4SourceId={admin4SourceId}
          availableFeatures={availableFeatures}
          hoverIdentifier={
            _.get(currentHoverFeature, "type") === "country" &&
            _.get(currentHoverFeature, "identifier")
          }
        />

        <div className="absolute bottom-0 left-0 ml-2 mb-10">
          <NavigationControl showCompass={false} />
        </div>
        <MapMarkers
          zoom={viewport.zoom}
          markerData={markerData}
          setMapClickDisabled={(val) => {
            mapClickDisabled.current = val;
          }}
        />
      </ReactMapGL>
    </div>
  );
}
