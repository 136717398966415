import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import AppStateContext from '../contexts/AppState';
import { getTopRegionTimeseriesData } from '../domain/api';
import { useQuery } from 'react-query';
import _ from 'lodash';
import Select from 'react-select';
import GlobalChart from './GlobalChart';
import { chartChoices, graphOptions, optionConfig } from '../chartUtil';
import HundredCasesChart from './HundredCasesChart';

const GlobalChartFrame = ({
  states,
  regionChart,
  chartView,
  parentUsstate,
  parentOption,
  parentChoice,
  parentAxis,
  highlightPath,
  showLegend,
  setShowLegend,
  fetchDataType,
  isMobile,
}) => {
  // const appState = useContext(AppStateContext);
  // const [fetchDataType, setFetchDataType] = useState('cumulative');

  // Get data for countries, states and counties
  const region_list = useMemo(() => {
    let subData = regionChart.data ? regionChart.data['subStats'] : [];
    let output = [];
    let init_path = [];
    if (chartView === 'country') {
      subData = regionChart.data ? regionChart.data['subStats']['US']['subStats'] : [];
      init_path.push('US');
    }
    if (chartView === 'state' || (chartView === 'county' && parentUsstate.value)) {
      init_path.push('US');
      if(parentUsstate.value === 'All' && states.length > 0)
        subData = regionChart.data ? regionChart.data['subStats']['US']['subStats'][states[1].value]['subStats'] : [];
      else
        subData = regionChart.data ? regionChart.data['subStats']['US']['subStats'][parentUsstate.value]['subStats'] : [];
      init_path.push(parentUsstate.value);
    }
    if (subData) {
      subData = _.filter(subData, function (o) {
        return !(o.name.startsWith('Out of') || o.name.startsWith('Unassigned'));
      });

      let selectedOption = parentOption ? parentOption : 'Confirmed';
      let sortingKey = optionConfig[selectedOption].param;

      let sortedSubStats = _.sortBy(subData, (s) => {
        return _.get(s, ['stat', sortingKey], 0);
      });
      let path_list = _.map(_.slice(sortedSubStats.reverse(), 0, 25), 'name');
      _.forEach(path_list, (path) => {
        let temp_list = [];
        temp_list.push(...init_path);
        temp_list.push(path);
        output.push(temp_list);
      });
      return output;
    }
  }, [regionChart.data, chartView, parentUsstate]);
  // Get data for top regions
  const topRegionTimeseries = useQuery(
    [
      'topRegionTimeseries',
      { path: region_list, dataType: fetchDataType, chart_view: chartView, parentUsstate: parentUsstate },
    ],
    getTopRegionTimeseriesData
  );

  // useEffect(() => {
  //   if (parentOption) {
  //     let value = parentOption.value;
  //     if (value.includes('Daily')) appState.actions.toggleDataType('charts','new');
  //     else appState.actions.toggleDataType('charts','cumulative');
  //   }
  // }, [parentOption]);

  let showLegends = () => {
    setShowLegend(!showLegend);
  };

  return (
    <div id='dropdown' style={{ marginTop: chartView !== 'Global' ? '3rem' : '' }}>
      <div className='mt-10'>
        <div className='chart-title font-extrabold text-2xl text-sharecare-darkgreen leading-none'>
          {chartView === 'country' ? 'Top States' : chartView === 'Global' ? 'Top Countries' : 'Top Counties'}
          <p style={{ fontSize: '12px', fontWeight: 500 }}>
            {(chartView === 'state' || chartView === 'county') ? '(Days since 20 cases)' : '(Days since 100 cases)'}</p>
        </div>
        <div className='flex justify-end' style={{ padding: '0 20px', float: 'right' }}>
          <button className='chart-button' onClick={showLegends}>
            {showLegend ? `Hide Legend` : `Show Legend`}
          </button>
        </div>
      </div>
      <>
        <HundredCasesChart
          states={states}
          topRegionTimeseries={topRegionTimeseries}
          option={parentOption}
          highlight={
            chartView === 'country'
              ? highlightPath[1]
              : chartView === 'state' || chartView === 'county'
              ? highlightPath[2]
              : highlightPath[0]
          }
          choice={parentChoice}
          isMobile={isMobile}
          highlightPath={highlightPath}
          graphType={parentAxis}
          chartView={chartView}
          parentUsstate={parentUsstate}
          setShowLegend={setShowLegend}
          showLegend={showLegend}
          fetchDataType={fetchDataType}
        />
      </>
    </div>
  );
};
export default GlobalChartFrame;
