import React from "react";
import { render } from "react-dom";
import App from "./App";
import AppStateContext from "./contexts/AppState";
import "./styles.css";

var mountNode = document.getElementById("app");
render(
  <AppStateContext.Provider>
    <App />
  </AppStateContext.Provider>,
  mountNode
);
