import React from "react";
import _ from "lodash";
import { numberWithCommas } from "../util";

function MapTooltip({ name, filteredStatsConfig, isPerCapita, stats }) {
  return (
    <div className="bg-black rounded p-2 text-white absolute map-tooltip">
      <div className="text-sm font-extrabold mb-1 leading-tight">{name}</div>
      {isPerCapita && (
        <div className="text-sm leading-tight mb-1">Per 100,000</div>
      )}
      {_.map(stats, (value, key) => {
        const statConfig = _.find(filteredStatsConfig, ['id', key])
        return (
          <div
            key={key}
            className={`flex justify-between text-white leading-tight mb-1 text-xs`}
          >
            <div className="font-semibold text-right mr-2 flex items-center leading-none">
              <div
                className={`rounded-full bg-sharecare-${statConfig.color} h-3 w-3 mr-2`}
              />
              {statConfig.label}
            </div>
            <div className=" px-2 font-extrabold relative text-right leading-none">
              <div className="relative">{numberWithCommas(value)}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(MapTooltip);
