import { Desktop as TopBar } from '../../components/TopBar';
import React, { useContext, useEffect, useState, useRef } from 'react';
import AppStateContext from '../../contexts/AppState';
import ChartFrame from '../../components/ChartFrame'

export default function () {
    const appState = useContext(AppStateContext);
    // Create copy of path in app state as this only way
    // We are able to get current state of Map
    const new_Path = [];
    Object.assign(new_Path, appState.path);

    const [chartView, setChartView] = useState(appState.path.includes('US') ? appState.data.type : 'Global');

    const firstUpdate = useRef(true);
    useEffect(() => {
        if (chartView && !firstUpdate.current) {
            setChartView(appState.chartView);
        }

        if (firstUpdate.current) {
            if (appState.path.includes('US')) {
                if (appState.data.type === 'county') appState.actions.setChartView('state');
                else appState.actions.setChartView(appState.data.type);
            }
            else appState.actions.setChartView(chartView);

            firstUpdate.current = false;
        }
    }, [appState.chartView]);

    return (
        <>
            <div
                className='hide-native-scrollbar'
                style={{ overflow: 'auto', overflowX: 'hidden' }}
            >
                {/*Mobility Chart*/}
                {chartView !== 'Global' ? (
                    <div>
                        <ChartFrame
                            chartName= "MobilityChart"
                            isMobile={false}
                            chartView={chartView}
                        />
                    </div>
                ) : (
                    ''
                )}
                {/*Hundred cases chart*/}
                <ChartFrame
                    chartName= "GlobalChartFrame"
                    isMobile={false}
                    chartView={chartView}
                />
                {/*counties chart*/}
                {chartView === 'country'  ? (
                    <div className='mt-10'>
                        <ChartFrame
                            chartName= "CountyChart"
                            isMobile={false}
                            chartView={chartView}
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
        </>
    );
}
