import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import AppStateContext from '../contexts/AppState';
import _, {map} from 'lodash';
import Chartjs from 'chart.js';
import colors from '../domain/colors';
import {abbreviateNumber, numberWithCommas} from '../util';
import {chartDataset, optionConfig} from '../chartUtil';
import {useQuery} from 'react-query';
import {getRegionTimeseries} from '../domain/api';
import Legend from './Legend';

const MAP_WIDTH_DESKTOP = 1500;
const MAP_WIDTH_MOBILE = 1500;

const HundredCasesChart = ({
  topRegionTimeseries,
  option,
  isMobile,
  choice,
  highlight,
  highlightPath,
  graphType,
  chartView,
  parentUsstate,
  setShowLegend,
  showLegend,
  fetchDataType,
}) => {
  const appState = useContext(AppStateContext);
  const regionData = useQuery(
    [
      'regionData',
      {
        path: highlight !== 'All' ? (chartView === 'country' ? highlightPath.slice(0, 2) : highlightPath) : [],
        dataType: fetchDataType,
        option: option,
        chartView: chartView,
        choice: choice,
      },
    ],
    getRegionTimeseries
  );

  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [orientation, setOrientation] = useState('portrait');
  const [highlightData, setHighLightData] = useState(null);
  const [legends, setLegends] = useState([]);
  let rectangleSet = false;

  let firstTimeScroll = useRef(false);
  useEffect(() => {
    if (showLegend) firstTimeScroll.current = true;
    if (!showLegend) firstTimeScroll.current = false;
  }, [showLegend]);

  useEffect(() => {
    if(chartView)
      firstTimeScroll.current = false;
  }, [chartView]);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  const chartConfig = {
    type: 'line',
    spanGaps: false,
    data: {
      labels: ['No Data'],
      datasets: chartDataset,
    },
    options: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          fontColor: 'white',
        },
      },
      tooltips: {
        // enabled: isMobile ? false : true,
        displayColors: false,
        callbacks: {
          title: function (tooltipItems, data) {
            //Return value for title
            return `Date: ${data.datasets[tooltipItems[0].datasetIndex].dates[tooltipItems[0].index]}`;
          },
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            return label ? `${label}:${numberWithCommas(tooltipItem.yLabel)}` : '';
          },
          beforeLabel: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            return label ? `Day: ${tooltipItem.label}` : '';
          },
        },
      },
      scales: {
        yAxes: [
          {
            id: 'A',
            type: graphType.value,
            position: 'left',
            ticks: {
              beginAtZero: true,
              fontColor: 'white',
              autoSkip: true,
              maxTicksLimit: 10,
              callback: function (label, index, labels) {
                return `${abbreviateNumber(label, true)} `;
              },
            },
            gridLines: {
              display: true,
              color: '#2d2d2d',
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              fontColor: 'white',
              autoSkip: true,
              maxTicksLimit: 20,
              maxRotation: 0,
              minRotation: 0,
            },
          },
        ],
      },
      maintainAspectRatio: false,
      responsive: true,
      animation: {
        duration: 0,
        onProgress: function (animation) {},
        onComplete: function (animation) {
          const mapWidth = isMobile ? MAP_WIDTH_MOBILE : MAP_WIDTH_DESKTOP;
          const scale = window.devicePixelRatio;

          var sourceCanvas = animation.chart.canvas;
          var copyAWidth = animation.chart.scales['A'].width - 10;
          var copyAHeight = animation.chart.scales['A'].height + animation.chart.scales['A'].top + 10;

          if (document.getElementById('100-axis-A')) {
            var targetCtx = document.getElementById('100-axis-A').getContext('2d');
            targetCtx.scale(scale, scale);
            targetCtx.canvas.width = copyAWidth * scale;
            targetCtx.canvas.height = copyAHeight * scale;

            targetCtx.canvas.style.width = `${copyAWidth}px`;
            targetCtx.canvas.style.height = `${copyAHeight}px`;
            targetCtx.drawImage(
              sourceCanvas,
              0,
              0,
              copyAWidth * scale,
              copyAHeight * scale,
              0,
              0,
              copyAWidth * scale,
              copyAHeight * scale
            );
          }
          const devWidth = window.innerWidth - 35;
          rectangleSet = true;
          if (!firstTimeScroll.current) {
            document.getElementById('100-areaWrapper').scrollLeft = mapWidth;
            firstTimeScroll.current = true;
          }
        },
      },
    },
  };

  const refresh = (loc) => {
    if (loc !== "chart" && showLegend === false){
      if (highlightData !== null && highlightData.hasOwnProperty("indexing")) {
        chartInstance.data.datasets[0] = chartInstance.data.datasets[highlightData.indexing];
        chartInstance.data.datasets[highlightData.indexing] = highlightData;
        chartInstance.data.datasets[highlightData.indexing].borderWidth = 1
        chartInstance.update()
      }
    }
    if(loc === "chart") {
      if (highlightData !== null && highlightData.hasOwnProperty("indexing")) {
        chartInstance.data.datasets[0] = chartInstance.data.datasets[highlightData.indexing];
        chartInstance.data.datasets[highlightData.indexing] = highlightData;
        chartInstance.data.datasets[highlightData.indexing].borderWidth = 1
      }
    }
  };

  const reinitializeDataset = (lineData) => {
    chartInstance.options.scales.yAxes[0].type = graphType.value;
    let dataset = chartInstance.data.datasets;

    for (let i = 0; i < chartInstance.data.datasets.length; i++) {
      dataset[i].data = Array(lineData[0].data.length).fill(0);
      dataset[i].label = '';
      dataset[i].showLine = false;
      dataset[i].borderWidth = 0;
      dataset[i].pointRadius = 0;
      dataset[i].pointHoverRadius = 0;
      dataset[i].indexing = i;
    }
  };

  const updateDataset = (lineData, variableData) => {
    if (chartInstance && lineData.length > 0) {
      let count = 1;
      let labelsLength = 0;

      // Initialize dataset
      refresh("chart");
      reinitializeDataset(lineData);
      let dataset = chartInstance.data.datasets;

      //new dataset
      _.forEach(lineData, (value) => {
        if (value && count < 26) {
          dataset[count].data = value.data;
          dataset[count].dates = value.dates;
          dataset[count].label = value.country;
          dataset[count].borderWidth = 1;
          if (count >= choice.value + 1 && count < 26) {
            dataset[count].showLine = false;
            dataset[count].pointRadius = 0;
            dataset[count].pointHoverRadius = 0;
          } else {
            dataset[count].showLine = true;
            dataset[count].pointRadius = 1.5;
            dataset[count].pointHoverRadius = 1.5;
            if (labelsLength < dataset[count].data.length) labelsLength = dataset[count].data.length;
          }
          count++;
        }
      });

      // Highlight data
      let selectRegionIndex = lineData.findIndex((data) => data.country === highlight);
      if (variableData.country && (selectRegionIndex === -1 || selectRegionIndex >= choice.value)) {
        dataset[26].data = variableData.data;
        dataset[26].label = variableData.country;
        dataset[26].dates = variableData.dates;
        dataset[26].showLine = true;
        dataset[26].borderWidth = 1;
        dataset[26].pointRadius = 1.5;
        dataset[26].pointHoverRadius = 1.5;
        dataset[26].borderColor = colors.red;
        if (labelsLength < dataset[26].data.length) labelsLength = dataset[26].data.length;
      }
      chartInstance.data.labels = [...Array(labelsLength).keys()];
      chartInstance.update();

    }
  };

  const checkDate = (recordDate, cutoffDate) => {
    let recordDateArr = recordDate.split('-');
    let cutoffDateArr = cutoffDate.split('-');

    var d1 = Number(recordDateArr[2] + recordDateArr[0] + recordDateArr[1]);
    var d2 = Number(cutoffDateArr[2] + cutoffDateArr[0] + cutoffDateArr[1]);
    return d1 > d2;
  };

  const getFormattedDateLabel = (date) => {
    var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    let dateArr = date.split('-');
    return mS[Number(dateArr[0] - 1)] + ' ' + dateArr[1];
  };

  const data = useMemo(() => {
    let lineData = [];
    let variableData = {};
    let key = '';
    if (chartView === 'Global') key = 0;
    if (chartView === 'country') key = 1;
    if (chartView === 'state' || chartView === 'county') key = 2;
    if (topRegionTimeseries.data) {
      _.forEach(topRegionTimeseries.data, (value) => {
        let count = 0;
        let sum = 0;
        let temp_data = { data: [], country: value['region'].split(':')[key], dates: [] };
        let new_data = value.stat.sort(function (a, b) {
          let aArr = a.day.split('-');
          let bArr = b.day.split('-');

          var d1 = Number(aArr[2] + aArr[0] + aArr[1]);
          var d2 = Number(bArr[2] + bArr[0] + bArr[1]);
          return d1 == d2 ? 0 : +(d1 > d2) || -1;
        });
        _.forEach(new_data, (value1, index) => {
          let selectedOption = option ? option : 'Confirmed';
          let cases = (chartView === "state" || chartView === "county") ? 20 : 100;
          if(fetchDataType !== 'new') {
            if (value1.stat[optionConfig[selectedOption].param] >= cases) {
              temp_data['dates'].push(getFormattedDateLabel(value1.day));
              temp_data['data'].push(value1.stat[optionConfig[selectedOption].param]);
            }
          }
          else{
            if (sum < cases) {
              sum = sum + value1.stat[optionConfig[selectedOption].param]
            }
            if(sum >= cases) {
              temp_data['dates'].push(getFormattedDateLabel(value1.day));
              temp_data['data'].push(value1.stat[optionConfig[selectedOption].param]);
            }
          }
          count++;
        });
        lineData.push(temp_data);
      });
    }

    // variable data based on country selection
    if (regionData.data && typeof regionData.data['region'] === 'string') {
      variableData = { data: [], country: regionData.data['region'].split(':')[key], dates: [] };
      let new_data = regionData.data.stat.sort(function (a, b) {
        let aArr = a.day.split('-');
        let bArr = b.day.split('-');

        var d1 = Number(aArr[2] + aArr[0] + aArr[1]);
        var d2 = Number(bArr[2] + bArr[0] + bArr[1]);
        return d1 == d2 ? 0 : +(d1 > d2) || -1;
      });
      let sum = 0;
      _.forEach(new_data, (value, index) => {
        let selectedOption = option ? option : 'Confirmed';
        let cases = (chartView === "state" || chartView === "county") ? 20 : 100;
        if(fetchDataType !== 'new') {
          if (value.stat[optionConfig[selectedOption].param] >= cases) {
            variableData['dates'].push(getFormattedDateLabel(value.day));
            variableData['data'].push(value.stat[optionConfig[selectedOption].param]);
          }
        }
        else{
          if (sum < cases) {
            sum = sum + value.stat[optionConfig[selectedOption].param]
          }
          if(sum >= cases) {
            variableData['dates'].push(getFormattedDateLabel(value.day));
            variableData['data'].push(value.stat[optionConfig[selectedOption].param]);
          }
        }
      });
    }

    return { lineData, variableData };
  }, [topRegionTimeseries.data, regionData.data]);

  useEffect(()=>{
    const { lineData, variableData } = data;
    let legend_list = [];
    // let filterData = _.filter(lineData, o => {return o.data});
    if(lineData.length > 0) {
      let iteretee = lineData.length > choice.value ? choice.value : lineData.length;
      for (let i = 0; i < iteretee; i++) {
        if(lineData[i].data && lineData[i].data.length > 0)
          legend_list.push(lineData[i].country);
      }
    }

    if(variableData && variableData.hasOwnProperty("country") && variableData.country) {
      if (!legend_list.includes(variableData.country)) {
        if (variableData.data && variableData.data.length > 0)
          legend_list.push(variableData.country);
      }
    }
    setLegends(legend_list)

  },[data, choice]);

  useEffect(() => {
    if (topRegionTimeseries.data) {
      const { lineData, variableData } = data;
      updateDataset(lineData, variableData);
    }

    const handleOrientationChange = () => {
      if (window.matchMedia('(orientation: portrait)').matches) {
        setOrientation('portrait');
      }

      if (window.matchMedia('(orientation: landscape)').matches) {
        setOrientation('landscape');
      }
      if (chartInstance) {
        // firstTimeScroll.current = false;
        chartInstance.update();
      }
    };

    window.addEventListener('orientationchange', handleOrientationChange);
    return () => window.removeEventListener('orientationchange', handleOrientationChange);
  }, [data, option, choice, highlight, graphType, chartView, parentUsstate]);

  const mouseOver = (event) => {
    if (event.target.id) {
      let elem = document.getElementById(event.target.id);
      if (elem) elem.style.backgroundColor = '#33bc9d';
      let index = Number(event.target.id.split('-')[1]);
      if (index > 0) {
        if (highlightData !== null && highlightData.hasOwnProperty("indexing")){
            chartInstance.data.datasets[0] = chartInstance.data.datasets[highlightData.indexing];
            chartInstance.data.datasets[highlightData.indexing] = highlightData;
          chartInstance.data.datasets[highlightData.indexing].borderWidth = 1
        }
        setHighLightData(chartInstance.data.datasets[index]);
        let arr = chartInstance.data.datasets[0];
        chartInstance.data.datasets[0] = chartInstance.data.datasets[index];
        chartInstance.data.datasets[0].borderWidth = 5;
        chartInstance.data.datasets[index] = arr;

        chartInstance.update();
      }
    }
  };

  const mouseOut = (event) => {
    if (event.target.id) {
      let elem = document.getElementById(event.target.id);
      if (elem) elem.style.backgroundColor = '#000';
      let index = Number(event.target.id.split('-')[1]);
      if (index > 0) {
      }
    }
  };
  let showLegends = () => {
    setShowLegend(!showLegend);
  };
  const devWidth = window.innerWidth - 35;

  return (
    <>
      <div style={{ padding: '0 20px', position: 'relative' }}>
        <div style={{ position: 'absolute', top: chartView === 'Global' ? 0 : 40, right: 10 }}>
          {showLegend && (
            <div className='chart-legend-div hide-native-scrollbar'>
              {chartInstance && (
                <div>
                  {map(legends, (dataset, key) => (
                    <div key={key}>
                      <Legend
                        chartName='Hundred'
                        current={key + 1 === choice.value + 1 ? 26: key+1 }
                        color={key+1 === choice.value+1 ? colors.red : colors.lineColorPallete[key+1]}
                        label={dataset}
                        mouseOver={mouseOver}
                        mouseOut={mouseOut}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className='mt-10 flex chartSpacing'>
        <div className='chartWrapper' id='wrapper'>
          <div className='chartAreaWrapper' id='100-areaWrapper' style={{ width: devWidth }}>
            <div className='chartAreaWrapperglobal' id='100-wrapper2'>
              <canvas id='100-chart-Test' height='400' width='1500' ref={chartContainer}></canvas>
            </div>
          </div>
          <canvas id='100-axis-A' height='400' width='0' style={{ backgroundColor: 'black' }}></canvas>
        </div>
      </div>
    </>
  );
};

export default HundredCasesChart;
