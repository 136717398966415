import React, { useContext, useState } from "react";
import _ from "lodash";
import AppStateContext from "../contexts/AppState";
import statsConfig from "../domain/stats";
import logoImage from "/assets/Sharecare.png";
import { MapMarked, LineChart, Sliders, Play, Globe, List, BarChart } from "./icons";
import { chartBar } from '../chartUtil';

function PageLink({ isActive, Icon, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`h-full flex items-center px-3 cursor-pointer transition-all duration-100 transition-ease ${
        isActive ? 'text-white' : 'text-black opacity-25 hover:opacity-75'
      }`}
    >
      <Icon
        className="h-4 w-4 block"
        isactive={isActive ? 1 : 0}
      />
    </div>
  );
}

function PageLinks({ appState }) {
  const page = _.get(appState, "page");
  return (
    <div className="rounded-full bg-sharecare-darkgreen justify-center h-full flex h-44 w-full">
      <PageLink
        Icon={MapMarked}
        onClick={(e) => appState.actions.setPage("map")}
        isActive={page === "map"}
      />
      <PageLink
        Icon={LineChart}
        onClick={(e) => appState.actions.setPage("charts")}
        isActive={page === "charts"}
      />
      <PageLink
        Icon={Play}
        onClick={(e) => {
          appState.actions.setPage("usCountyAnimation")
          appState.actions.setPath(["US"])
          appState.actions.toggleStat('active', false)
          appState.actions.toggleStat('recovered', false)
          appState.actions.toggleStat('dead', false)
          !appState.mapRightPanelHidden && appState.actions.toggleMapRightPanelHidden()
        }}
        isActive={page === "usCountyAnimation"}
      />
    </div>
  );
}

function StatGroups({ appState }) {
  const path = _.get(appState, 'path', []);
  const disabledFields = path.length ? ['active', 'recovered'] : appState.dataType === 'new' ? ['active'] : [];
  const activeStatsCount = _.filter(_.get(appState, 'stats'), (isActive) => isActive).length;
  const hide = appState.page === "usCountyAnimation" || appState.mobileTab.map === "usCountyAnimation"
  return (
    <>
      {!hide && statsConfig.map((stat, idx) => {
        const isLast = idx === statsConfig.length - 1;
        const isChecked = _.get(appState, ['stats', stat.id]);
        const disabledGlobally = disabledFields.indexOf(stat.id) !== -1;
        const isDisabled = disabledGlobally || (activeStatsCount === 1 && isChecked);
        return (
          <label
            className='flex items-center cursor-pointer uppercase font-semibold text-xs leading-none mr-3 transition-opacity duration-300 ease-in-out whitespace-no-wrap'
            key={stat.id}
            style={{
              opacity: disabledGlobally ? 0.5 : 1,
            }}
          >
            <input
              className={`mr-1 form-checkbox h-4 w-4 transition-colors duration-300 ease-in-out text-sharecare-${
                stat.color
              } ${disabledGlobally ? 'bg-gray-500' : ''}`}
              type='checkbox'
              checked={isChecked}
              disabled={isDisabled}
              onChange={(e) => appState.actions.toggleStat(stat.id, e.target.checked)}
            />
            {stat.label}
          </label>
        );
      })}
    </>
  );
}

function ChartGroups({ appState }) {
  return (
    <>
      {chartBar.map((region, idx) => {
        return (
          <label
            className='flex items-center cursor-pointer uppercase font-semibold text-xs leading-none mr-4 transition-opacity duration-300 ease-in-out whitespace-no-wrap'
            key={region.id}
            style={{ opacity: 1 }}
          >
            <input
              className={`mr-1 form-checkbox h-4 w-4 transition-colors duration-300 ease-in-out bg-gray-500 text-sharecare-${region.color}`}
              type='checkbox'
              checked={_.get(appState, 'chartView') === region.id}
              onChange={(e) => appState.actions.setChartView(region.id)}
            />
            {region.label}
          </label>
        );
      })}
    </>
  );
}

function Toggle({ appState, label, type }) {
  // let checkedType = (type !== "capita") ? appState.dataType === type : appState.toggleType === type;
  const typeData = { new: 'dataType', capita: 'toggleType', hundred: 'chartType' };
  return (
    <label className='flex items-center cursor-pointer uppercase font-semibold text-xs leading-none whitespace-no-wrap mr-3'>
      <div className='relative toggle-button mr-1'>
        <input
          className='hidden'
          type='checkbox'
          checked={appState[typeData[type]] === type}
          onChange={(e) => appState.actions.toggleDataType(type, appState)}
        />
        <div className='w-4 h-4 border-sharecare-darkgreen border-solid border-2 rounded-full dot absolute'></div>
        <div className='w-8 h-4 border-sharecare-darkgreen border-solid border-2 rounded-full'></div>
      </div>
      {label}
    </label>
  );
}

export function Desktop() {
  const appState = useContext(AppStateContext);
  const hide = appState.page === "usCountyAnimation" || appState.mobileTab.map === "usCountyAnimation"

  return (
    <div className='bg-white py-4 px-4 rounded-full flex h-44'>
      <img src={logoImage} className='h-3 mr-4 block' />
      {appState.page === 'charts' ? (
        <>
          <ChartGroups appState={appState} />
          {/*<Toggle appState={appState} label={<>100 Cases</>} type='hundred' />*/}
        </>
      ) : (
        <>
          <StatGroups appState={appState} />
         {!hide  && (
           <>
           <Toggle appState={appState} label={<>Daily</>} type='new' />
          <Toggle appState={appState} label={<>Per Capita</>} type='capita' />
           </>
         )
          }
        </>
      )}
      <div className='-my-4 -mr-4'>
        <PageLinks appState={appState} />
      </div>
    </div>
  );
}

export function Mobile() {
  const appState = useContext(AppStateContext);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const hide = appState.page === "usCountyAnimation" || appState.mobileTab.map === "usCountyAnimation"

  return (
    <>
      <div className={`bg-white px-6 rounded-22 mx-4 mt-4 ${!filtersVisible ? 'h-44' : ''}`}>
        <div className='flex items-center justify-between h-44'>
          <img src={logoImage} className='h-3 mr-4 block' />

         {!hide && <button
            className='text-black font-semibold leading-none flex items-center'
            onClick={(e) => setFiltersVisible(!filtersVisible)}
          >
            <Sliders className='text-sharecare-darkgreen h-6 w-6 mr-2' />
            COVID19 Filters
          </button>}
        </div>
        {filtersVisible ? (
          <div className='pb-2 mt-4 mx-4'>
            {appState.mobileTab['map'] === 'chart' ? (
              <>
                <div className='grid grid-cols-2 col-gap-4 row-gap-6 mb-6'>
                  <ChartGroups appState={appState} />
                  {/*<Toggle appState={appState} label={<>100 Cases</>} type='hundred' />*/}
                </div>
              </>
            ) : (
              <>
                <div className='grid grid-cols-2 col-gap-4 row-gap-6 mb-6'>
                  <StatGroups appState={appState} />
                </div>
                <div className='grid grid-cols-2 col-gap-4 row-gap-4 mb-3'>
                  <Toggle appState={appState} label={<>Daily</>} type='new' />
                  <Toggle appState={appState} label={<>Per Capita</>} type='capita' />
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
}
