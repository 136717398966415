import React, { useContext } from "react";
import { ChevronLeft } from "./icons";
import _ from "lodash";
import AppStateContext from "../contexts/AppState";

export default function (props) {
  const appState = useContext(AppStateContext);
  const data = _.get(appState, "data");
  const { className, isMobile } = props;
  const textSize = isMobile ? 'text-l' : 'text-4xl'
  const marginRight = isMobile ? null : 'mr-6'
  return (
    <div className={`${className}`}>
      {appState.parentRegionName ? (
        <div
          className={`text-white cursor-pointer uppercase flex items-center font-semibold leading-none ${marginRight} hover:underline`}
          onClick={appState.actions.goBack}
        >
          <ChevronLeft className="h-5" />
          {`${!isMobile ? 'BACK TO' : ''} ${appState.parentRegionName}`}
        </div>
      ) : null}
      <div className={`${isMobile && 'mt-4'} font-extrabold ${textSize} text-sharecare-darkgreen leading-none`}>
        {_.get(data, "name")}
      </div>
    </div>
  );
}
