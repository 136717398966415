/* Enumerate classes that will be dynamically generated so we prevent PurgeCSS from removing them */
const protected = [
  "text-sharecare-yellow",
  "text-sharecare-purple",
  "text-sharecare-red",
  "text-sharecare-blue",
  "bg-sharecare-yellow",
  "bg-sharecare-purple",
  "bg-sharecare-red",
  "bg-sharecare-blue",
  "bg-gray-500",
  "bg-sharecare-gray",
];

const lineColorPallete = ['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC', '#C278AA', '#B9C735', '#ED3A94', '#F1997E', '#B2E341', '#FA5AAC']

module.exports = {
  yellow: "#E0AB0B",
  purple: "#B336BE",
  red: "#FF1919",
  blue: "#29B2F0",
  darkgreen: "#33BC9D",
  gray: "#3B3B39",
  maroon: "#800000",
  lightpurple: "#b19cd9",
  "map-background": "#16322C",
  "yellow-semi": "rgba(224, 171, 11, 0.5)",
  "darkgreen-semi": "rgba(51, 188, 156, 0.7)",
  lineColorPallete
};
