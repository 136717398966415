import React,{useEffect} from "react";

const Legend = ({chartName, current="", color,  label, mouseOver="", mouseOut=""}) => {
    let new_style = {background : color, marginTop:"5px"};
    useEffect(() => {
    })
    return(
        <div className="hover-legend" id={`${chartName}-${current}`}
             onMouseOver={(event)=> {
                     if(mouseOver) mouseOver(event)
                 }}
             onMouseOut={(event)=> {
                     if(mouseOut) mouseOut(event)
                 }}
             style={{display:"flex", padding:"2px"}}
        >
            <div className="rectangle" style={new_style}></div>

           <p style={{color:"white",margin: "0 5px"}}>{label} </p>
        </div>
    )
}

export default Legend